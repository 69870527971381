import React, {MouseEvent} from 'react';
import {LoadingOutlined} from '@ant-design/icons';

type Props = {
    className?: string,
    title: string,
    type?: string,
    onClick?: (e: MouseEvent<HTMLElement>) => void,
    loading?: boolean,
    size?: 'small' | 'default',
    mood?: 'positive' | 'negative'
    disabled?: boolean
} & React.ComponentPropsWithRef<"button">;

const Button = ({
                    title,
                    type,
                    className,
                    size = 'default',
                    loading,
                    mood = 'positive',
                    ...props
                }: Props) => {
    const disabled = props.disabled || loading;
    return (
        <button id={"button-container"}
                className={`${className} type={type} ${disabled ? ' disabled' : ' active'} ${mood} ${size}`}
                disabled={disabled} {...props}>
            <span id={"button-text-without-icon"}>{loading ? <LoadingOutlined/> : title}</span>
        </button>
    );
};

export default Button;
