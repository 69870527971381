import React, {ChangeEvent,useState} from 'react';
import {useHistory} from "react-router-dom";
import "./Login.css";
import {authLogin, requestNewPassword} from "../../redux/actions/authActions";
import {useIntl} from "react-intl";
import {Form, Input, Modal} from "antd";
import {notify, Status} from "reapop";
import EmailIcon from "../../assets/svg/icon_email.svg";
import PasswordIcon from "../../assets/svg/icon_password.svg";
import TextInput from "../../components/common/text-input/TextInput";
import Button from "../../components/common/buttons/Button";
import {connect} from "react-redux";
import {RootState} from "../../redux/store";

type LoginProps = StateProps & DispatchProps;

const Login = ({authenticated, loading,showToast,authLogin,requestNewPassword} : LoginProps) => {

    const history = useHistory();
    const intl = useIntl();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const onSubmit = (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        initiateLogin();
    }

    const initiateLogin = () => {
        if (!email) {
            showToast(intl.formatMessage({id: 'pls_enter_your_email'}), 'error');
            return
        }
        if (!password) {
            showToast(intl.formatMessage({id: 'pls_enter_your_password'}), 'error');
            return
        }
        authLogin(email, password);
        if(authenticated) {
            history.push('/');
        }
    }

    const onForgotPassword = () => {
        setShowForgotPasswordModal(true);
    }

    const onDismissForgotPassword = () => {
        setShowForgotPasswordModal(false);
    }

    const onRequestNewPassword = () => {
        if (loading) {
            return;
        }
        if (!forgotPasswordEmail) {
            showToast(intl.formatMessage({id: 'pls_enter_your_email'}), 'error');
            return;
        }
        requestNewPassword(forgotPasswordEmail, () => setShowForgotPasswordModal(false));
    }

    return (
        <>
            <div className={"outer"} style={{marginTop: '20vh'}}>
                <div className={"inner"}>
                    <form onSubmit={onSubmit}>
                        <h3>WELCOME</h3>
                        <div className="form-group">
                            <TextInput imgPrefix={{
                                src: EmailIcon,
                                alt: intl.formatMessage({id: 'email'}),
                                backgroundColor: 'rgba(24, 144, 255, 0.1)'
                            }}
                                       label={intl.formatMessage({id: 'email'})}
                                       placeholder={intl.formatMessage({id: 'enter_email'})} value={email}
                                       onChange={(event) => setEmail(event.target.value)}/>
                        </div>

                        <div className="form-group">
                            <TextInput imgPrefix={{
                                src: PasswordIcon,
                                alt: intl.formatMessage({id: 'password'}),
                                backgroundColor: 'rgba(24, 144, 255, 0.1)'
                            }}
                                       type={'password'}
                                       label={intl.formatMessage({id: 'password'})}
                                       placeholder={intl.formatMessage({id: 'enter_password'})} value={password}
                                       onChange={(event) => setPassword(event.target.value)}/>

                        </div>

                        <Button type={'submit'} title={intl.formatMessage({id: 'login'})}
                                loading={loading} onSubmit={initiateLogin}
                                className={"d-flex center"}/>

                        <p className="forgot-password text-right">
                            <a className={"text-decoration-none"} href={'/login'} onClick={(e) => {
                                e.preventDefault();
                                onForgotPassword();
                            }}>{intl.formatMessage({id: 'forgot_password_question'})}</a>
                        </p>
                    </form>
                </div>
            </div>

            <Modal okButtonProps={{disabled: loading}} cancelButtonProps={{disabled: loading}}
                   open={showForgotPasswordModal} onOk={onRequestNewPassword} onCancel={onDismissForgotPassword}
                   title={intl.formatMessage({id: 'request_new_password'})}>
                <Form.Item>
                    <Input value={forgotPasswordEmail} onChange={(e) => setForgotPasswordEmail(e.target.value)}
                           placeholder={intl.formatMessage({id: 'email_address'})}/>
                </Form.Item>
            </Modal>
    </>
    );
};


interface StateProps {
    authenticated: boolean,
    loading: boolean,
    error: string | null
}
interface DispatchProps {
    authLogin: (email: string, password: string) => void,
    requestNewPassword: (email: string, callback: () => void) => void,
    showToast: (message: string, status: Status) => void
}
const mapStateToProps = (state: RootState): StateProps => {
    return {
        authenticated: !!(state.authReducer.roles && state.authReducer.id),
        loading: state.authReducer.loading,
        error: state.authReducer.error ?? null
    };
};
const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => {
    return {
        authLogin: (email, password) => dispatch(authLogin(email, password)),
        requestNewPassword: (email, callback) => dispatch(requestNewPassword(email, callback)),
        showToast: (message, status) => dispatch(notify(message, status))
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Login);

