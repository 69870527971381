import {persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import rootReducer from "./reducers/rootReducer";
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'authReducer',
        'hospitalReducer',
        'userReducer',
        'currentUserReducer'
    ],
    // Blacklist (Don't Save Specific Reducers)
    blacklist: [
        'notifications',
        'patientReducer',
        'doctorReducer',
        'appointmentReducer',
        'partnershipReducer',
        'frontDeskAdminReducer',
        'patientRecordReducer',
        'consultationReducer',
        'doctorScheduleReducer',
        'departmentReducer'
    ],
}

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunk)
));

let persistor = persistStore(store)

export {
    store,
    persistor,
};

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

