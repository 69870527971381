import {notify} from 'reapop';
import {RootState} from "../store";
import {convertListToIdMap} from "../../utils/utils";
import {DoctorScheduleType, DoctorScheduleUpdateType} from "../../types/doctor";
import React from "react";
import axios from "axios";


export const UPDATE_DOCTOR_SCHEDULE_START = "UPDATE_DOCTOR_SCHEDULE_START";
export const UPDATE_DOCTOR_SCHEDULE_SUCCESS = "UPDATE_DOCTOR_SCHEDULE_SUCCESS";
export const UPDATE_DOCTOR_SCHEDULE_FAILURE = "UPDATE_DOCTOR_SCHEDULE_FAILURE";

export const FETCH_DOCTOR_SCHEDULE_START = "FETCH_DOCTOR_SCHEDULE_START";
export const FETCH_DOCTOR_SCHEDULE_SUCCESS = "FETCH_DOCTOR_SCHEDULE_SUCCESS";
export const FETCH_DOCTOR_SCHEDULE_FAILURE = "FETCH_DOCTOR_SCHEDULE_FAILURE";

export const FETCH_DOCTORS_SCHEDULES_START = "FETCH_DOCTORS_SCHEDULES_START";
export const FETCH_DOCTORS_SCHEDULES_SUCCESS = "FETCH_DOCTORS_SCHEDULES_SUCCESS";
export const FETCH_DOCTORS_SCHEDULES_FAILURE = "FETCH_DOCTORS_SCHEDULES_FAILURE";


export const updateDoctorScheduleStart = () => ({
    type: UPDATE_DOCTOR_SCHEDULE_START
});

export const updateDoctorScheduleSuccess = (payload: DoctorScheduleType) => ({
    type: UPDATE_DOCTOR_SCHEDULE_SUCCESS,
    payload
});

const updateDoctorScheduleFailure = (error: string) => ({
    type: UPDATE_DOCTOR_SCHEDULE_FAILURE,
    error
});

export const fetchDoctorScheduleStart = () => ({
    type: FETCH_DOCTOR_SCHEDULE_START
})

export const fetchDoctorScheduleSuccess = (doctorId: string, payload: DoctorScheduleType) => ({
    type: FETCH_DOCTOR_SCHEDULE_SUCCESS,
    doctorId, payload
})

const fetchDoctorScheduleFailure = (error: string) => ({
    type: FETCH_DOCTOR_SCHEDULE_FAILURE,
    error
});


export const fetchDoctorsSchedulesStart = () => ({
    type: FETCH_DOCTORS_SCHEDULES_START
});

export const fetchDoctorsSchedulesSuccess = (payload: { id: string, name: string, schedule: DoctorScheduleType }[]) => ({
    type: FETCH_DOCTORS_SCHEDULES_SUCCESS,
    payload: convertListToIdMap(payload)
});

const fetchDoctorsSchedulesFailure = (error: string) => ({
    type: FETCH_DOCTORS_SCHEDULES_FAILURE,
    error
});

const BASE_URL = "https://providers-dev-api.dokitari.com";
export const updateDoctorSchedule = (doctorId: string, payload: DoctorScheduleUpdateType, dismissDialog: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;

        const hospitalId = getState().userReducer.user.hospitalId;
        dispatch(updateDoctorScheduleStart());
        axios.patch(BASE_URL+`/api/doctors/${doctorId}/schedule?hospital-id=${hospitalId}`,JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => updateDoctorSchedule(doctorId, payload, dismissDialog), (error) => updateDoctorScheduleFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(updateDoctorScheduleFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Schedule updated', 'success'));
                dispatch(updateDoctorScheduleSuccess(response))
                dismissDialog()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(updateDoctorScheduleFailure(error));
        });
    }
};

export const fetchDoctorSchedule = (doctorId: string, callback: (doctorSchedule: { id: string, name: string, schedule: DoctorScheduleType }) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;

        const hospitalId = getState().userReducer.user.hospitalId;
        dispatch(fetchDoctorScheduleStart());

      axios.get(BASE_URL + `/api/doctors/${doctorId}/schedule?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.data.errorMessage || response.data.error) {
                    dispatch(notify(response.data.errorMessage ?? response.data.error, 'error'));
                    dispatch(fetchDoctorScheduleFailure(response.data.errorMessage ?? response.data.error));
                } else {
                    dispatch(fetchDoctorScheduleSuccess(doctorId, response.data));
                    callback(response.data);
                }
            })
            .catch(error => {
                console.log("we are hereeee")
                console.log(error);
                dispatch(notify(error, 'error'));
                dispatch(fetchDoctorScheduleFailure(error));
            });
    }
};

export const fetchDoctorsSchedules = () => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;

        dispatch(fetchDoctorsSchedulesStart());
        fetch(BASE_URL+`/api/doctors/schedules?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.json();
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchDoctorsSchedules(), (error) => fetchDoctorsSchedulesFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDoctorsSchedulesFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchDoctorsSchedulesSuccess(response));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchDoctorsSchedulesFailure(error));
        });
    }
};
