import authReducer from "./authReducer";
import patientReducer from "./patientReducer";
import doctorReducer from "./doctorReducer";
import appointmentReducer from "./appointmentReducer";
import partnershipReducer from "./partnershipReducer";
import frontDeskAdminReducer from "./frontDeskAdminReducer";
import patientRecordReducer from "./patientRecordReducer";
import {combineReducers} from "redux";
import {reducer as notificationsReducer} from 'reapop'
import consultationReducer from "./consultationReducer";
import doctorScheduleReducer from "./doctorScheduleReducer";
import hospitalReducer from "./hospitalReducer";
import userReducer from "./userReducer";
import departmentReducer from "./departmentReducer";

const rootReducer = combineReducers({
    notifications: notificationsReducer(), authReducer, patientReducer, appointmentReducer,
    doctorReducer, partnershipReducer, frontDeskAdminReducer, patientRecordReducer,
    consultationReducer, doctorScheduleReducer, hospitalReducer, userReducer, departmentReducer
});

export default rootReducer;
