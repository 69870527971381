import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {AppointmentType} from "../../types/appointment";
import {
    ADD_MEETING_NOTE_FAILURE,
    ADD_MEETING_NOTE_START,
    ADD_MEETING_NOTE_SUCCESS,
    FETCH_APPOINTMENT_FAILURE,
    FETCH_APPOINTMENT_START,
    FETCH_APPOINTMENT_SUCCESS,
    FETCH_DOCTOR_APPOINTMENTS_FAILURE,
    FETCH_DOCTOR_APPOINTMENTS_START,
    FETCH_DOCTOR_APPOINTMENTS_SUCCESS,
    FETCH_APPOINTMENTS_FAILURE,
    FETCH_APPOINTMENTS_START,
    FETCH_APPOINTMENTS_SUCCESS,
    FETCH_PATIENT_APPOINTMENTS_FAILURE,
    FETCH_PATIENT_APPOINTMENTS_START,
    FETCH_PATIENT_APPOINTMENTS_SUCCESS,
    CANCEL_APPOINTMENT_FAILURE,
    CANCEL_APPOINTMENT_START,
    CANCEL_APPOINTMENT_SUCCESS,
    REGISTER_APPOINTMENT_FAILURE,
    REGISTER_APPOINTMENT_START,
    REGISTER_APPOINTMENT_SUCCESS,
    RESCHEDULE_APPOINTMENT_FAILURE,
    RESCHEDULE_APPOINTMENT_START, CLOSE_APPOINTMENT_SUCCESS, CLOSE_APPOINTMENT_START, CLOSE_APPOINTMENT_FAILURE,

} from "../actions/appointmentActions";


const initState: { loading: boolean, error?: string, appointments: { [id: string]: AppointmentType } } = {
    appointments: {},
    loading: false,
};

const appointmentReducer = (state = initState, action: any) => {
    switch (action.type) {
        case REGISTER_APPOINTMENT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case REGISTER_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    [action.payload.id]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case REGISTER_APPOINTMENT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case RESCHEDULE_APPOINTMENT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case RESCHEDULE_APPOINTMENT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_DOCTOR_APPOINTMENTS_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_DOCTOR_APPOINTMENTS_SUCCESS: {
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    ...action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_DOCTOR_APPOINTMENTS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_PATIENT_APPOINTMENTS_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_PATIENT_APPOINTMENTS_SUCCESS: {
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    ...action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_PATIENT_APPOINTMENTS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_APPOINTMENTS_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_APPOINTMENTS_SUCCESS: {
            return {
                ...state,
                appointments: action.payload,
                loading: false,
                error: undefined
            }
        }
        case FETCH_APPOINTMENTS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_APPOINTMENT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    [action.payload.id]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_APPOINTMENT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case CANCEL_APPOINTMENT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case CANCEL_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    [action.payload.id]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case CANCEL_APPOINTMENT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case ADD_MEETING_NOTE_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case ADD_MEETING_NOTE_SUCCESS: {
            let appointment = state.appointments[action.appointmentId];
            appointment['meetingNotes'] = action.payload;
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    [action.appointmentId]: appointment
                },
                loading: false,
                error: undefined
            }
        }
        case ADD_MEETING_NOTE_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case CLOSE_APPOINTMENT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case CLOSE_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: undefined
            }
        }
        case CLOSE_APPOINTMENT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default appointmentReducer;
