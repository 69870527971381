import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {CurrentUserType} from "../../types/user";
import {
    FETCH_USER_START,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    UPDATE_USER_START,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE
} from "../actions/userActions";


const initState: { loading: boolean, user: CurrentUserType | undefined } = {
    loading: false,
    user: undefined
};

const userReducer = (state = initState, action: any) => {

    switch (action.type) {
        case FETCH_USER_START: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCH_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        }
        case FETCH_USER_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        case UPDATE_USER_START: {
            return {
                ...state,
                loading: true,
            }
        }
        case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        }
        case UPDATE_USER_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default userReducer;
