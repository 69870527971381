import {notify} from 'reapop';
import {RootState} from "../store";
import {convertListToIdMap} from "../../utils/utils";
import {
    PatientAccountCreationPayloadType, PatientAccountEditPayloadType,
    PatientType
} from "../../types/patient";
import axios from "axios";
import React from "react";


export const CREATE_PATIENT_ACCOUNT_START = "CREATE_PATIENT_ACCOUNT_START";
export const CREATE_PATIENT_ACCOUNT_SUCCESS = "CREATE_PATIENT_ACCOUNT_SUCCESS";
export const CREATE_PATIENT_ACCOUNT_FAILURE = "CREATE_PATIENT_ACCOUNT_FAILURE";

export const EDIT_PATIENT_ACCOUNT_START = "EDIT_PATIENT_ACCOUNT_START";
export const EDIT_PATIENT_ACCOUNT_SUCCESS = "EDIT_PATIENT_ACCOUNT_SUCCESS";
export const EDIT_PATIENT_ACCOUNT_FAILURE = "EDIT_PATIENT_ACCOUNT_FAILURE";

export const FETCH_PATIENTS_ACCOUNT_START = "FETCH_PATIENTS_ACCOUNT_START";
export const FETCH_PATIENTS_ACCOUNT_SUCCESS = "FETCH_PATIENTS_ACCOUNT_SUCCESS";
export const FETCH_PATIENTS_ACCOUNT_FAILURE = "FETCH_PATIENTS_ACCOUNT_FAILURE";

export const FETCH_PATIENT_ACCOUNT_START = "FETCH_PATIENT_ACCOUNT_START";
export const FETCH_PATIENT_ACCOUNT_SUCCESS = "FETCH_PATIENT_ACCOUNT_SUCCESS";
export const FETCH_PATIENT_ACCOUNT_FAILURE = "FETCH_PATIENT_ACCOUNT_FAILURE";

export const DELETE_PATIENT_ACCOUNT_START = "DELETE_PATIENT_ACCOUNT_START";
export const DELETE_PATIENT_ACCOUNT_SUCCESS = "DELETE_PATIENT_ACCOUNT_SUCCESS";
export const DELETE_PATIENT_ACCOUNT_FAILURE = "DELETE_PATIENT_ACCOUNT_FAILURE";


export const createPatientAccountStart = () => ({
    type: CREATE_PATIENT_ACCOUNT_START
})

export const createPatientAccountSuccess = (payload: PatientType) => ({
    type: CREATE_PATIENT_ACCOUNT_SUCCESS,
    payload
})

const createPatientAccountFailure = (error: string) => ({
    type: CREATE_PATIENT_ACCOUNT_FAILURE,
    error
});


export const editPatientAccountStart = () => ({
    type: EDIT_PATIENT_ACCOUNT_START
})

export const editPatientAccountSuccess = (payload: PatientType) => ({
    type: EDIT_PATIENT_ACCOUNT_SUCCESS,
    payload
})

const editPatientAccountFailure = (error: string) => ({
    type: EDIT_PATIENT_ACCOUNT_FAILURE,
    error
});

export const fetchPatientsAccountStart = () => ({
    type: FETCH_PATIENTS_ACCOUNT_START
})

export const fetchPatientsAccountSuccess = (payload: PatientType[]) => ({
    type: FETCH_PATIENTS_ACCOUNT_SUCCESS,
    payload: convertListToIdMap(payload)
})

const fetchPatientsAccountFailure = (error: string) => ({
    type: FETCH_PATIENTS_ACCOUNT_FAILURE,
    error
});

export const fetchPatientAccountStart = () => ({
    type: FETCH_PATIENT_ACCOUNT_START
})

export const fetchPatientAccountSuccess = (payload: PatientType) => ({
    type: FETCH_PATIENT_ACCOUNT_SUCCESS,
    payload
});


const fetchPatientAccountFailure = (error: string) => ({
    type: FETCH_PATIENT_ACCOUNT_FAILURE,
    error
});

export const deletePatientAccountStart = () => ({
    type: DELETE_PATIENT_ACCOUNT_START
})

export const deletePatientAccountSuccess = (payload: PatientType) => ({
    type: DELETE_PATIENT_ACCOUNT_SUCCESS,
    payload
});

const deletePatientAccountFailure = (error: string) => ({
    type: DELETE_PATIENT_ACCOUNT_FAILURE,
    error
});

const BASE_URL = "https://providers-dev-api.dokitari.com";
export const createPatientAccount = (payload: PatientAccountCreationPayloadType, onSuccess: () => void, onFailure: (errorMessage: string) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(createPatientAccountStart());
        axios.post(BASE_URL+`/api/patients?hospital-id=${hospitalId}`, JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(createPatientAccountFailure(response.errorMessage ?? response.error));
                onFailure(response.errorMessage ?? response.error)
            } else {
                dispatch(notify('Patient Account created successfully', 'success'))
                dispatch(createPatientAccountSuccess(response))
                onSuccess()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(createPatientAccountFailure(error));
            onFailure(error)
        });
    }
};

export const editPatientAccount = (patientId: string, payload: PatientAccountEditPayloadType) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(editPatientAccountStart());
        axios.patch(BASE_URL+`/api/patients/${patientId}?hospital-id=${hospitalId}`,JSON.stringify(payload), {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => editPatientAccount(patientId, payload), (error) => editPatientAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(editPatientAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Patient Account updated successfully', 'success'))
                dispatch(editPatientAccountSuccess(response))
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(editPatientAccountFailure(error));
        });
    }
};


//TODO: Separate this into fetchHospitalPatients and fetchDoctorPatients. Then the condition should be moved to the UI component instead.
export const fetchHospitalPatients = (callback?: (patient: PatientType[]) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().userReducer.user.hospitalId;
        dispatch(fetchPatientsAccountStart());
        axios.get(BASE_URL+`/api/patients/hospitals/${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchPatientsAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchPatientsAccountSuccess(response));
                if (callback) {
                    callback(response);
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchPatientsAccountFailure(error));
        });
    }
};

export const fetchDoctorPatients = (callback?: (patient: PatientType[]) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let doctorId = getState().userReducer.user.id;
        dispatch(fetchPatientsAccountStart());
        axios.get(BASE_URL+`/api/patients/doctors/${doctorId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchPatientsAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchPatientsAccountSuccess(response));
                if (callback) {
                    callback(response);
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchPatientsAccountFailure(error));
        });
    }
};

export const fetchPatient = (patientId: string, callback?: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;
        //let tester = getState().authReducer.tester;
        dispatch(fetchPatientAccountStart());
        axios.get(BASE_URL+`/api/patients/${patientId}?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchPatient(patientId), (error) => fetchPatientAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchPatientAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchPatientAccountSuccess(response));
                if (callback) {
                    callback();
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchPatientAccountFailure(error));
        });
    }
};


export const deletePatient = (patientId: string) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;
        //let tester = getState().authReducer.tester;
        dispatch(deletePatientAccountStart());
        axios.delete(BASE_URL+`/api/patients/${patientId}?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => deletePatient(patientId), (error) => deletePatientAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(deletePatientAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(deletePatientAccountSuccess(response));
                dispatch(notify("Account deleted successfully", 'success'));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(deletePatientAccountFailure(error));
        });
    }
};
