import {
    FETCH_PARTNERSHIP_START, FETCH_PARTNERSHIP_SUCCESS, FETCH_PARTNERSHIP_FAILURE
} from "../actions/partnershipActions";
import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {PartnershipType} from "../../types/partnership";

const initState: { loading: boolean, partnership?: PartnershipType } = {
    loading: false
};

const partnershipReducer = (state = initState, action: { type: string, partnership?: any, error?: string }) => {

    switch (action.type) {
        case FETCH_PARTNERSHIP_SUCCESS: {
            return {
                ...state,
                partnership: action.partnership,
                loading: false,
                error: null
            }
        }
        case FETCH_PARTNERSHIP_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case FETCH_PARTNERSHIP_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default partnershipReducer;
