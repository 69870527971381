import {
    CREATE_DOCTOR_ACCOUNT_START,
    CREATE_DOCTOR_ACCOUNT_SUCCESS,
    CREATE_DOCTOR_ACCOUNT_FAILURE,
    FETCH_DOCTORS_ACCOUNTS_START,
    FETCH_DOCTORS_ACCOUNTS_SUCCESS,
    FETCH_DOCTORS_ACCOUNTS_FAILURE,
    FETCH_DOCTOR_ACCOUNT_FAILURE,
    FETCH_DOCTOR_ACCOUNT_START,
    FETCH_DOCTOR_ACCOUNT_SUCCESS,
    EDIT_DOCTOR_ACCOUNT_START,
    EDIT_DOCTOR_ACCOUNT_SUCCESS,
    EDIT_DOCTOR_ACCOUNT_FAILURE,
    DELETE_DOCTOR_ACCOUNT_SUCCESS, DELETE_DOCTOR_ACCOUNT_FAILURE, DELETE_DOCTOR_ACCOUNT_START,
} from "../actions/doctorActions";
import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {DoctorType} from "../../types/doctor";

const initState: { doctors: { [id: string]: DoctorType }, loading: boolean, error?: string | null } = {
    doctors: {},
    loading: false,
};


const doctorReducer = (state = initState, action: { payload?: { [id: string]: DoctorType } | DoctorType, id?: string, error?: string, type?: string }) => {

    switch (action.type) {
        case CREATE_DOCTOR_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case CREATE_DOCTOR_ACCOUNT_SUCCESS: {
            return {
                ...state,
                doctors: {
                    ...state.doctors,
                    [action.id!]: action.payload! as DoctorType
                },
                loading: false,
                error: null
            }
        }
        case CREATE_DOCTOR_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case EDIT_DOCTOR_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case EDIT_DOCTOR_ACCOUNT_SUCCESS: {
            return {
                ...state,
                doctors: {
                    ...state.doctors,
                    [action.id!]: action.payload! as DoctorType
                },
                loading: false,
                error: null
            }
        }
        case EDIT_DOCTOR_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_DOCTORS_ACCOUNTS_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case FETCH_DOCTORS_ACCOUNTS_SUCCESS: {

            return {
                ...state,
                doctors: action.payload! as { [id: string]: DoctorType },
                loading: false,
                error: null
            }
        }
        case FETCH_DOCTORS_ACCOUNTS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case  FETCH_DOCTOR_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case  FETCH_DOCTOR_ACCOUNT_SUCCESS: {

            return {
                ...state,
                doctors: {
                    ...state.doctors,
                    [action.id!]: action.payload! as DoctorType
                },
                loading: false,
                error: undefined
            }
        }
        case  FETCH_DOCTOR_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case DELETE_DOCTOR_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case DELETE_DOCTOR_ACCOUNT_SUCCESS: {
            const doctor = action.payload as DoctorType;
            const doctors = state.doctors;
            delete doctors[doctor.id];
            return {
                ...state,
                doctors,
                loading: false,
                error: undefined
            }
        }
        case DELETE_DOCTOR_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default doctorReducer;
