import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {FrontDeskAdminType} from "../../types/frontDeskAdmin";
import {
    CREATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE,
    CREATE_FRONT_DESK_ADMIN_ACCOUNT_START,
    CREATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS, DELETE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE,
    DELETE_FRONT_DESK_ADMIN_ACCOUNT_START,
    DELETE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS,
    FETCH_FRONT_DESK_ADMIN_ACCOUNT_FAILURE,
    FETCH_FRONT_DESK_ADMIN_ACCOUNT_START,
    FETCH_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS,
    FETCH_FRONT_DESK_ADMINS_ACCOUNT_FAILURE,
    FETCH_FRONT_DESK_ADMINS_ACCOUNT_START,
    FETCH_FRONT_DESK_ADMINS_ACCOUNT_SUCCESS,
    UPDATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE,
    UPDATE_FRONT_DESK_ADMIN_ACCOUNT_START,
    UPDATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS
} from "../actions/frontDeskAdminActions";


const initState: { loading: boolean, error?: string, frontDeskAdmins: { [id: string]: FrontDeskAdminType } } = {
    loading: false,
    frontDeskAdmins: {}
};

const frontDeskAdminReducer = (state = initState, action: any) => {

    switch (action.type) {
        case CREATE_FRONT_DESK_ADMIN_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case CREATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS: {
            return {
                ...state,
                frontDeskAdmins: {
                    ...state.frontDeskAdmins,
                    [action.payload.userId]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case CREATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case UPDATE_FRONT_DESK_ADMIN_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case UPDATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS: {
            return {
                ...state,
                frontDeskAdmins: {
                    ...state.frontDeskAdmins,
                    [action.payload.userId]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case UPDATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case DELETE_FRONT_DESK_ADMIN_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case DELETE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS: {
            const frontDeskAdmin = action.payload as FrontDeskAdminType;
            const frontDeskAdmins = state.frontDeskAdmins;
            delete frontDeskAdmins[frontDeskAdmin.id];
            return {
                ...state,
                frontDeskAdmins,
                loading: false,
                error: undefined
            }
        }
        case DELETE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_FRONT_DESK_ADMINS_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_FRONT_DESK_ADMINS_ACCOUNT_SUCCESS: {

            return {
                ...state,
                frontDeskAdmins: action.payload,
                loading: false,
                error: undefined
            }
        }
        case FETCH_FRONT_DESK_ADMINS_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_FRONT_DESK_ADMIN_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS: {
            return {
                ...state,
                frontDeskAdmins: {
                    ...state.frontDeskAdmins,
                    [action.payload.userId]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_FRONT_DESK_ADMIN_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default frontDeskAdminReducer;
