import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {
    UPDATE_DOCTOR_SCHEDULE_START,
    UPDATE_DOCTOR_SCHEDULE_SUCCESS,
    UPDATE_DOCTOR_SCHEDULE_FAILURE,
    FETCH_DOCTOR_SCHEDULE_START,
    FETCH_DOCTOR_SCHEDULE_FAILURE,
    FETCH_DOCTOR_SCHEDULE_SUCCESS,
    FETCH_DOCTORS_SCHEDULES_START, FETCH_DOCTORS_SCHEDULES_SUCCESS, FETCH_DOCTORS_SCHEDULES_FAILURE,
} from "../actions/doctorScheduleActions";
import {DoctorScheduleType} from "../../types/doctor";


const initState: { loading: boolean, error?: string, doctors: { [id: string]: { schedule: DoctorScheduleType, id: string, name: string } } } = {
    loading: false,
    doctors: {}
};

const doctorScheduleReducer = (state = initState, action: any) => {

    switch (action.type) {
        case UPDATE_DOCTOR_SCHEDULE_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case UPDATE_DOCTOR_SCHEDULE_SUCCESS: {
            return {
                ...state,
                doctors: {
                    ...state.doctors,
                    [action.doctorId]: {
                        ...state.doctors[action.doctorId],
                        schedule: action.payload
                    }
                },
                loading: false,
                error: undefined
            }
        }
        case UPDATE_DOCTOR_SCHEDULE_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_DOCTOR_SCHEDULE_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_DOCTOR_SCHEDULE_SUCCESS: {

            return {
                ...state,
                doctors: {
                    ...state.doctors,
                    [action.doctorId]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_DOCTOR_SCHEDULE_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_DOCTORS_SCHEDULES_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_DOCTORS_SCHEDULES_SUCCESS: {

            return {
                ...state,
                doctors: action.payload,
                loading: false,
                error: undefined
            }
        }
        case FETCH_DOCTORS_SCHEDULES_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default doctorScheduleReducer;
