import {notify} from 'reapop';
import {RootState} from "../store";
import {PatientRecordCreationPayloadType, PatientRecordType} from "../../types/patient";
import axios from "axios";
import React from "react";

export const CREATE_PATIENT_RECORD_START = "CREATE_PATIENT_RECORD_START";
export const CREATE_PATIENT_RECORD_SUCCESS = "CREATE_PATIENT_RECORD_SUCCESS";
export const CREATE_PATIENT_RECORD_FAILURE = "CREATE_PATIENT_RECORD_FAILURE";

export const UPDATE_PATIENT_RECORD_START = "UPDATE_PATIENT_RECORD_START";
export const UPDATE_PATIENT_RECORD_SUCCESS = "UPDATE_PATIENT_RECORD_SUCCESS";
export const UPDATE_PATIENT_RECORD_FAILURE = "UPDATE_PATIENT_RECORD_FAILURE";

export const FETCH_PATIENT_RECORDS_START = "FETCH_PATIENT_RECORDS_START";
export const FETCH_PATIENT_RECORDS_SUCCESS = "FETCH_PATIENT_RECORDS_SUCCESS";
export const FETCH_PATIENT_RECORDS_FAILURE = "FETCH_PATIENT_RECORDS_FAILURE";


function convertAppointmentListToMap(records: PatientRecordType[]) {
    let recordsMap: { [id: string]: PatientRecordType } = {};
    records.forEach(record => {
        recordsMap[record.id!!] = record;
    });
    return recordsMap;
}


export const createPatientRecordStart = () => ({
    type: CREATE_PATIENT_RECORD_START
});

export const createPatientRecordSuccess = (payload: PatientRecordType) => ({
    type: CREATE_PATIENT_RECORD_SUCCESS,
    payload
});

const createPatientRecordFailure = (error: string) => ({
    type: CREATE_PATIENT_RECORD_FAILURE,
    error
});


export const updatePatientRecordStart = () => ({
    type: UPDATE_PATIENT_RECORD_START
});

export const updatePatientRecordSuccess = (payload: PatientRecordType) => ({
    type: UPDATE_PATIENT_RECORD_SUCCESS,
    payload
});

const updatePatientRecordFailure = (error: string) => ({
    type: UPDATE_PATIENT_RECORD_FAILURE,
    error
});

export const fetchPatientRecordsStart = () => ({
    type: FETCH_PATIENT_RECORDS_START
})

export const fetchPatientRecordsSuccess = (payload: PatientRecordType[]) => ({
    type: FETCH_PATIENT_RECORDS_SUCCESS,
    payload: convertAppointmentListToMap(payload)
})

const fetchPatientRecordsFailure = (error: string) => ({
    type: FETCH_PATIENT_RECORDS_FAILURE,
    error
});


const BASE_URL = "https://providers-dev-api.dokitari.com";
export const createPatientRecord = (patientId: string, payload: PatientRecordCreationPayloadType, dismissDialog: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(createPatientRecordStart());
        axios.post(BASE_URL+`/api/patients/${patientId}/records?hospital-id=${hospitalId}`, JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => createPatientRecord(patientId, payload, dismissDialog), (error) => createPatientRecordFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(createPatientRecordFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Patient record added', 'success'));
                dispatch(createPatientRecordSuccess(response))
                dismissDialog();
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(createPatientRecordFailure(error));
        });
    }
};

export const updatePatientRecord = (patientId: string, recordId: string, payload: PatientRecordCreationPayloadType, dismissDialog: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;
        //let tester = getState().authReducer.tester;

        dispatch(updatePatientRecordStart());
        axios.patch(`/api/patients/${patientId}/records/${recordId}?hospital-id=${hospitalId}`, JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => updatePatientRecord(patientId, recordId, payload, dismissDialog), (error) => updatePatientRecordFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(updatePatientRecordFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Patient record updated', 'success'));
                dispatch(updatePatientRecordSuccess(response))
                dismissDialog()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(updatePatientRecordFailure(error));
        });
    }
};


export const fetchPatientRecords = (patientId: string) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;
        //let tester = getState().authReducer.tester;
        dispatch(fetchPatientRecordsStart());
        axios.get(BASE_URL+`/api/patients/${patientId}/records?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchPatientRecords(patientId), (error) => fetchPatientRecordsFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchPatientRecordsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchPatientRecordsSuccess(response));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchPatientRecordsFailure(error));
        });
    }
};

