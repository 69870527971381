import {RootState} from "../store";
import {convertListToIdMap} from "../../utils/utils";
import {notify} from "reapop";
import {HospitalType} from "../../types/hospital";
import {HospitalProfilePayloadType} from "../../types/hospital";
import {NameId} from "../../types/commons";
import React from "react";
import axios from "axios";

export const FETCH_CURRENT_HOSPITAL_START = "FETCH_CURRENT_HOSPITAL_START";
export const FETCH_CURRENT_HOSPITAL_SUCCESS = "FETCH_CURRENT_HOSPITAL_SUCCESS";
export const FETCH_CURRENT_HOSPITAL_FAILURE = "FETCH_CURRENT_HOSPITAL_FAILURE";

export const FETCH_HOSPITALS_START = "FETCH_HOSPITALS_START";
export const FETCH_HOSPITALS_SUCCESS = "FETCH_HOSPITALS_SUCCESS";
export const FETCH_HOSPITALS_FAILURE = "FETCH_HOSPITALS_FAILURE";

export const UPDATE_HOSPITAL_START = "UPDATE_HOSPITAL_START";
export const UPDATE_HOSPITAL_SUCCESS = "UPDATE_HOSPITAL_SUCCESS";
export const UPDATE_HOSPITAL_FAILURE = "UPDATE_HOSPITAL_FAILURE";


export const fetchCurrentHospitalStart = () => ({
    type: FETCH_CURRENT_HOSPITAL_START
})

export const fetchCurrentHospitalSuccess = (payload: HospitalType) => ({
    type: FETCH_CURRENT_HOSPITAL_SUCCESS,
    payload
})

const fetchCurrentHospitalFailure = (error: string) => ({
    type: FETCH_CURRENT_HOSPITAL_FAILURE,
    error
});

export const fetchHospitalsStart = () => ({
    type: FETCH_HOSPITALS_START
})

export const fetchHospitalsSuccess = (payload: NameId[]) => ({
    type: FETCH_HOSPITALS_SUCCESS,
    payload: convertListToIdMap(payload)
})

const fetchHospitalsFailure = (error: string) => ({
    type: FETCH_HOSPITALS_FAILURE,
    error
});

export const updateHospitalStart = () => ({
    type: UPDATE_HOSPITAL_START
})

export const updateHospitalSuccess = (payload: HospitalType) => ({
    type: UPDATE_HOSPITAL_SUCCESS,
    payload
})

const updateHospitalFailure = (error: string) => ({
    type: UPDATE_HOSPITAL_FAILURE,
    error
});

const BASE_URL = "https://providers-dev-api.dokitari.com";

export const fetchCurrentHospital = (callback?: () => void) => {
    return async function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = await Promise.all([getState().authReducer.hospitalId]);
        if(hospitalId[0]){
            dispatch(fetchCurrentHospitalStart());
            axios.get(BASE_URL+`/api/hospitals/${hospitalId}`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                return response.data;
            }).then(response => {

                //     dispatch(renewAuthToken(() => fetchCurrentHospital(callback), (error) => fetchCurrentHospitalFailure(error)))

                if (response.errorMessage || response.error) {
                    dispatch(notify(response.errorMessage ?? response.error, 'error'));
                    dispatch(fetchCurrentHospitalFailure(response.errorMessage ?? response.error));
                } else {
                    dispatch(fetchCurrentHospitalSuccess(response));
                    if (callback) {
                        callback();
                    }
                }
            }).catch(error => {
                console.log(error);
                dispatch(notify(error, 'error'));
                dispatch(fetchCurrentHospitalFailure(error));
            });
        }
    }
};

export const fetchHospitals = (callback?: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;
        dispatch(fetchHospitalsStart());
        axios.get(BASE_URL+`/api/hospitals/${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchHospitals(callback), (error) => fetchHospitalsFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchHospitalsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchHospitalsSuccess([response]));
                if (callback) {
                    callback();
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchHospitalsFailure(error));
        });
    }
};
// {"id":"2ce6360a-5e3b-476e-b663-c47c9ed38ece","name":"A test hospitalk","contactPhone":"+905528476484",
// "contactEmail":"adeogo.hospital@gmail.com","address":"A nice hospital address","licenseInformation":"No info yetkfds",
// "operatingLicense":{"id":"031e1262-0bf9-4e6b-b11e-2969d243467b","uid":"031e1262-0bf9-4e6b-b11e-2969d243467b","name":"31a56643-39f1-4cdc-b8c3-8640125454b4.jpg",
// "url":"http://res.cloudinary.com/dokitari/image/upload/v1676979797/31a56643-39f1-4cdc-b8c3-8640125454b4_zoywiv.jpg","status":"done","type":"image/jpeg","size":122435}
//
//
// ,"hospitalLogo":{"id":"031e1262-0bf9-4e6b-b11e-2969d243467b","uid":null,"name":"31a56643-39f1-4cdc-b8c3-8640125454b4.jpg",
// "url":"http://res.cloudinary.com/dokitari/image/upload/v1676979797/31a56643-39f1-4cdc-b8c3-8640125454b4_zoywiv.jpg","status":"done","type":"image/jpeg","size":122435}
//
// ,"errorMessage":null}
export const updateHospital = (payload: HospitalProfilePayloadType) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;

        dispatch(updateHospitalStart());
        axios.patch(BASE_URL+`/api/hospitals/${hospitalId}`, JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => updateHospital(payload), (error) => updateHospitalFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(updateHospitalFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Hospital profile updated', 'success'));
                dispatch(updateHospitalSuccess(response))
            }
        }).catch(error => {
            dispatch(notify(error, 'error'));
            dispatch(updateHospitalFailure(error));
        });
    }
};
