import {
    AUTH_LOGIN_START,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAILURE, AUTH_LOGOUT_SUCCESS, AUTH_TOKEN_RENEWAL_SUCCESS, FETCH_CURRENT_USER_DETAILS_START, FETCH_CURRENT_USER_DETAILS_SUCCESS, FETCH_CURRENT_USER_DETAILS_FAILURE,
} from "../actions/authActions";
import {AuthType} from "../../types/auth";
import {FETCH_USER_SUCCESS} from "../actions/userActions";



const initState: AuthType = {
    loading: false,
    id: null,
    hospitalId: null,
    accountType: undefined,
    roles: null,
    error: null,
    refreshToken: null,
};

const authReducer = (state = initState,
                     action: { type: string, payload: any } & AuthType) => {
    switch (action.type) {
        case AUTH_LOGIN_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case AUTH_LOGIN_SUCCESS: {
            return {
                ...state,
                id: action.id,
                hospitalId: action.hospitalId,
                accountType: action.accountType,
                refreshToken: action.refreshToken,
                token: action.token,
                idToken:action.idToken,
                roles: action.roles,
                error: null,
                loading: false
            }
         }
        case AUTH_TOKEN_RENEWAL_SUCCESS: {
            return {
                ...state,
                refreshToken: action.refreshToken,
                token: action.token,
            }
        }
        case AUTH_LOGIN_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        case  FETCH_CURRENT_USER_DETAILS_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case FETCH_CURRENT_USER_DETAILS_SUCCESS:{
            return {
                ...state,
                id: action.id,
                hospitalId: action.hospitalId,
                accountType: action.accountType,
                roles: action.roles,
                error: null,
                loading: false
            }
        }
        case FETCH_USER_SUCCESS: {
            return {
                ...state,
                roles: action.payload.roles,
                hospitalId: action.payload.hospitalId,
            };
        }
        case FETCH_CURRENT_USER_DETAILS_FAILURE:{
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        default: {
            return state
        }
    }
};

export default authReducer;
