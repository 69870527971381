import {ConsultationType} from "../../types/consultation";
import {RootState} from "../store";
import {notify} from "reapop";
import axios from "axios";
import React from "react";

export const FETCH_DOCTOR_CONSULTATIONS_START = "FETCH_DOCTOR_CONSULTATIONS_START";
export const FETCH_DOCTOR_CONSULTATIONS_SUCCESS = "FETCH_DOCTOR_CONSULTATIONS_SUCCESS";
export const FETCH_DOCTOR_CONSULTATIONS_FAILURE = "FETCH_DOCTOR_CONSULTATIONS_FAILURE";

export const FETCH_PATIENT_CONSULTATIONS_START = "FETCH_PATIENT_CONSULTATIONS_START";
export const FETCH_PATIENT_CONSULTATIONS_SUCCESS = "FETCH_PATIENT_CONSULTATIONS_SUCCESS";
export const FETCH_PATIENT_CONSULTATIONS_FAILURE = "FETCH_PATIENT_CONSULTATIONS_FAILURE";

export const fetchDoctorConsultationsStart = () => ({
    type: FETCH_DOCTOR_CONSULTATIONS_START
})

export const fetchDoctorConsultationsSuccess = (doctorId: string, consultations: ConsultationType[]) => ({
    type: FETCH_DOCTOR_CONSULTATIONS_SUCCESS,
    payload: {doctorId, consultations: convertConsultationListToMap(consultations)}
})

const fetchDoctorConsultationsFailure = (error: string) => ({
    type: FETCH_DOCTOR_CONSULTATIONS_FAILURE,
    error
});

export const fetchPatientConsultationsStart = () => ({
    type: FETCH_PATIENT_CONSULTATIONS_START
})

export const fetchPatientConsultationsSuccess = (patientId: string, consultations: ConsultationType[]) => ({
    type: FETCH_PATIENT_CONSULTATIONS_SUCCESS,
    payload: {patientId, consultations: convertConsultationListToMap(consultations)}
})

const fetchPatientConsultationsFailure = (error: string) => ({
    type: FETCH_PATIENT_CONSULTATIONS_FAILURE,
    error
});

const BASE_URL = "https://providers-dev-api.dokitari.com";


function convertConsultationListToMap(consultations: ConsultationType[]) {
    let consultationMap: { [id: string]: ConsultationType } = {};
    consultations.forEach(consultation => {
        consultationMap[consultation.id] = consultation;
    });
    return consultationMap;
}

export const fetchDoctorConsultations = (doctorId: string, successCallback?: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;
        //let tester = getState().authReducer.tester;

        dispatch(fetchDoctorConsultationsStart());
        axios.get(BASE_URL+`/api/doctors/${doctorId}/consultations?hospital-id=${hospitalId}`,{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchDoctorConsultations(doctorId, successCallback),
            //         (error) => fetchDoctorConsultationsFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDoctorConsultationsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchDoctorConsultationsSuccess(doctorId, response))
                if (successCallback) {
                    successCallback()
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchDoctorConsultationsFailure(error));
        });
    }
};


export const fetchPatientConsultations = (patientId: string, successCallback?: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;
        //let tester = getState().authReducer.tester;

        dispatch(fetchPatientConsultationsStart());
        axios.get(BASE_URL+`/api/patients/${patientId}/consultations?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchPatientConsultations(patientId, successCallback),
            //         (error) => fetchPatientConsultationsFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchPatientConsultationsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchPatientConsultationsSuccess(patientId, response))
                if (successCallback) {
                    successCallback()
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchPatientConsultationsFailure(error));
        });
    }
};
