import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {ConsultationType} from "../../types/consultation";
import {
    FETCH_DOCTOR_CONSULTATIONS_FAILURE,
    FETCH_DOCTOR_CONSULTATIONS_START,
    FETCH_DOCTOR_CONSULTATIONS_SUCCESS,
    FETCH_PATIENT_CONSULTATIONS_FAILURE,
    FETCH_PATIENT_CONSULTATIONS_START,
    FETCH_PATIENT_CONSULTATIONS_SUCCESS
} from "../actions/consultationActions";


const initState: {
    loading: boolean, error?: string, doctors: { [id: string]: { [id: string]: ConsultationType } },
    patients: { [id: string]: { [id: string]: ConsultationType } }
} = {
    loading: false,
    doctors: {},
    patients: {}
};

const consultationReducer = (state = initState, action: any) => {

    switch (action.type) {
        case FETCH_DOCTOR_CONSULTATIONS_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_DOCTOR_CONSULTATIONS_SUCCESS: {
            return {
                ...state,
                doctors: {
                    ...state.doctors,
                    [action.payload.doctorId]: action.payload.consultations
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_DOCTOR_CONSULTATIONS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_PATIENT_CONSULTATIONS_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_PATIENT_CONSULTATIONS_SUCCESS: {
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.payload.patientId]: action.payload.consultations
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_PATIENT_CONSULTATIONS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default consultationReducer;
