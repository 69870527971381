import {ImageUrl} from "../types/commons";
import {UploadFile} from "antd/es/upload/interface";
import {AppointmentType} from "../types/appointment";
import dayjs from "dayjs";

const BASE_URL = "https://providers-dev-api.dokitari.com";

export const removeEmptyOrNull = (obj: any) => {
    Object.keys(obj).forEach((k: string) =>
        (obj[k] && typeof obj[k] === 'object') ? removeEmptyOrNull(obj[k]) : (!obj[k] && obj[k] !== undefined) && delete obj[k]
    );
    return obj;
};

export const getBasicJSONRequestHeaders = (token:string|null|undefined,isTester?: boolean | null): HeadersInit => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Accept', 'application/json');
    requestHeaders.set('User-Is-Tester', String(isTester ?? false));
    requestHeaders.set('Authorization',`Bearer ${token}`)
    return requestHeaders;
}

export const generateUniqueId = () => {
    const S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export const convertDateStringToWeekDayDateExpression = (date: string) => {
    return dayjs(date, "DD.MM.YYYY").format("dddd, DD MMMM YYYY");
}

export const capitalizeFirstLetter = (text: string) => {
    return text[0].toUpperCase() + text.substring(1);
}
export const convertIsoWeekdayToString = (weekdayNumber: number) => {
    switch (weekdayNumber) {
        case 1: {
            return 'monday';
        }
        case 2: {
            return 'tuesday';
        }
        case 3: {
            return 'wednesday';
        }
        case 4: {
            return 'thursday';
        }
        case 5: {
            return 'friday';
        }
        case 6: {
            return 'saturday';
        }
        case 7: {
            return 'sunday';
        }
        default: {
            throw new Error('Poor format');
        }
    }
}


export const validateNIN = (nin: string) => {
    return (nin.startsWith('CM') || nin.startsWith('CF')) && nin.length === 14;
}


export const convertImageUrlToAntdFormat = (imageUrl: ImageUrl): UploadFile<any> => {
    return {
        name: imageUrl?.name,
        response: {
            url: imageUrl?.url,
            status: imageUrl?.status as 'error' | 'success' | 'done' | 'uploading' | 'removed'
        },
        url: imageUrl?.url,
        size: imageUrl?.size,
        type: imageUrl?.type,
        uid: imageUrl?.id,
        xhr: undefined
    };
}

export const convertAntdFormatToImageUrl = (antdFormat: UploadFile<any>): ImageUrl => {
    return {
        id: antdFormat.response?.uid,
        name: antdFormat.name,
        size: antdFormat.size ?? 0,
        status: antdFormat.status as 'error' | 'success' | 'done' | 'uploading' | 'removed',
        type: antdFormat.type ?? 'png',
        uid: antdFormat.response?.uid,
        url: antdFormat.response?.url
    };
}

export function getUploadUrlObjectFromUrl(url: string): UploadFile[] {
    let urlPartsByFullStop = url.split('.');
    let urlPartsBySlash = url.split('/');
    const fileName = urlPartsBySlash[urlPartsBySlash.length - 1];
    const extension = urlPartsByFullStop[urlPartsByFullStop.length - 1];
    const extensionsMap: { [id: string]: string } = {
        'png': 'image/png',
        'jpeg': 'image/jpeg',
        'jpg': 'image/jpeg'
    };

    return [
        {
            "uid": "random id",
            "name": fileName,
            "url": url,
            "status": "done",
            "type": extensionsMap[extension],
            "response": {
                "url": url
            },
            "size": 0
        }
    ];
}


export function convertListToIdMap(items: { id: any }[]) {
    let map: { [id: string]: any } = {};
    items.forEach(item => {
        map[item.id] = item;
    });
    return map;
}

export function convertAppointmentListToDoctorsAppointmentsMapOfLists(items: AppointmentType[]): { [doctorId: string]: AppointmentType[] } {
    let map: { [id: string]: AppointmentType[] } = {};
    items.forEach(item => {
        if (item.doctor) {
            map[item.doctor.userId] ? map[item.doctor.userId].push(item) : map[item.doctor.userId] = [item];
        }
    });

    return map;
}

export function convertAppointmentListToPatientsAppointmentsMap(items: AppointmentType[]): { [patientId: string]: AppointmentType[] } {
    let map: { [id: string]: any } = {};
    items.forEach(item => {
        map[item.patient.id] ? map[item.patient.id].push(item) : map[item.patient.id] = [item];
    });
    return map;
}

export function getFullName({firstName, lastName}: { firstName?: string, lastName?: string }) {
    if (firstName && lastName) {
        return firstName + " " + lastName;
    } else if (firstName) {
        return firstName;
    } else if (lastName) {
        return lastName;
    } else {
        return "";
    }
}
export function validateEmail(email:any) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}
export function validatePhone(phone:any){
    const regexPhone = /^\+?\d{1,3}[- ]?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
    return regexPhone.test(phone)
}
export async function fetchHospitalDepartments(hospitalId: string) {
    const root = localStorage.getItem('persist:root')??"";
    const authReducer = JSON.parse(root).authReducer;
    const token = JSON.parse(authReducer).token;
    const res = await fetch(BASE_URL+`/api/hospitals/${hospitalId}/departments/names-and-ids`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    return await res.json();
}
