import {RootState} from "../store";
import {notify} from "reapop";
import {CurrentUserType, UserUpdatePayloadType} from "../../types/user";
import {NameId} from "../../types/commons";
import axios from "axios";
import React from "react";

export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

const fetchUserStart = () => ({
    type: FETCH_USER_START,
});

export const fetchUserSuccess = (payload: CurrentUserType) => ({
    type: FETCH_USER_SUCCESS,
    payload
});

const fetchUserFailure = (error: string) => ({
    type: FETCH_USER_FAILURE,
    error
});


const fetchUsersStart = () => ({
    type: FETCH_USERS_START,
});

const fetchUsersFailure = (error: string) => ({
    type: FETCH_USERS_FAILURE,
    error
});

const updateUserStart = () => ({
    type: UPDATE_USER_START,
});

export const updateUserSuccess = (payload: CurrentUserType) => ({
    type: UPDATE_USER_SUCCESS,
    payload
});

const updateUserFailure = (error: string) => ({
    type: UPDATE_USER_FAILURE,
    error
});


const BASE_URL = "https://providers-dev-api.dokitari.com";
export const fetchUser = (callback?: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let id = getState().authReducer.id;
        if (!id) {
            return;
        }
        dispatch(fetchUserStart());
        axios.get(BASE_URL+`/api/users/current-user`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            console.log(response.data)
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchUserFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchUserSuccess(response));
                if (callback) {
                    callback();
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchUserFailure(error));
        });
    }
};


export const fetchHospitalUsers = (callback?: (users: NameId[]) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().authReducer.hospitalId;

        dispatch(fetchUsersStart());
        axios.get(BASE_URL+`/api/hospitals/${hospitalId}/users`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchUsersFailure(response.errorMessage ?? response.error));
            } else {
                if (callback) {
                    callback(response);
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
        });
    }
};

export const updateUser = (body: UserUpdatePayloadType, callback?: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        dispatch(updateUserStart());
        axios.patch(BASE_URL+`/api/users/current-user`, JSON.stringify(body), {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(updateUserFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify("User profile updated", 'success'))
                dispatch(updateUserSuccess(response));
                if (callback) {
                    callback();
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchUserFailure(error));
        });
    }
};

