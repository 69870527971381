import {notify} from 'reapop';
import {RootState} from "../store";
import {convertListToIdMap} from "../../utils/utils";
import {
    DepartmentCreationPayloadType, DepartmentUpdatePayloadType, DepartmentType
} from "../../types/department";
import {DoctorType} from "../../types/doctor";
import axios from "axios";
import React from "react";

export const CREATE_DEPARTMENT_START = "CREATE_DEPARTMENT_START";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILURE = "CREATE_DEPARTMENT_FAILURE";

export const UPDATE_DEPARTMENT_START = "UPDATE_DEPARTMENT_START";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";

export const FETCH_DEPARTMENT_ACCOUNT_START = "FETCH_DEPARTMENT_ACCOUNT_START";
export const FETCH_DEPARTMENT_ACCOUNT_SUCCESS = "FETCH_DEPARTMENT_ACCOUNT_SUCCESS";
export const FETCH_DEPARTMENTS_ACCOUNT_FAILURE = "FETCH_DEPARTMENTS_ACCOUNT_FAILURE";

export const FETCH_DEPARTMENT_START = "FETCH_DEPARTMENT_START";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
export const FETCH_DEPARTMENT_FAILURE = "FETCH_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT_ACCOUNT_START = "DELETE_DEPARTMENT_ACCOUNT_START";
export const DELETE_DEPARTMENT_ACCOUNT_SUCCESS = "DELETE_DEPARTMENT_ACCOUNT_SUCCESS";
export const DELETE_DEPARTMENT_ACCOUNT_FAILURE = "DELETE_DEPARTMENT_ACCOUNT_FAILURE";


export const createDepartmentStart = () => ({
    type: CREATE_DEPARTMENT_START
})

export const createDepartmentSuccess = (payload: DepartmentType) => ({
    type: CREATE_DEPARTMENT_SUCCESS,
    payload
})

const createDepartmentFailure = (error: string) => ({
    type: CREATE_DEPARTMENT_FAILURE,
    error
});


export const updateDepartmentStart = () => ({
    type: UPDATE_DEPARTMENT_START
})

export const updateDepartmentSuccess = (payload: DepartmentType) => ({
    type: UPDATE_DEPARTMENT_SUCCESS,
    payload
})

const updateDepartmentFailure = (error: string) => ({
    type: UPDATE_DEPARTMENT_FAILURE,
    error
});


export const fetchDepartmentsAccountStart = () => ({
    type: FETCH_DEPARTMENT_ACCOUNT_START
})

export const fetchDepartmentsAccountSuccess = (payload: DepartmentType[]) => ({
    type: FETCH_DEPARTMENT_ACCOUNT_SUCCESS,
    payload: convertListToIdMap(payload)
})

const fetchDepartmentsAccountFailure = (error: string) => ({
    type: FETCH_DEPARTMENTS_ACCOUNT_FAILURE,
    error
});

export const fetchDepartmentStart = () => ({
    type: FETCH_DEPARTMENT_START
})

export const fetchDepartmentSuccess = (payload: DepartmentType) => ({
    type: FETCH_DEPARTMENT_SUCCESS,
    payload
})

const fetchDepartmentFailure = (error: string) => ({
    type: FETCH_DEPARTMENT_FAILURE,
    error
});
export const deleteDepartmentAccountStart = () => ({
    type: DELETE_DEPARTMENT_ACCOUNT_START
})

export const deleteDepartmentAccountSuccess = (payload: DoctorType) => ({
    type: DELETE_DEPARTMENT_ACCOUNT_SUCCESS,
    payload
});

const deleteDepartmentAccountFailure = (error: string) => ({
    type: DELETE_DEPARTMENT_ACCOUNT_FAILURE,
    error
});

const BASE_URL = "https://providers-dev-api.dokitari.com";
export const createDepartment = (payload: DepartmentCreationPayloadType,onComplete: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;

        dispatch(createDepartmentStart());
        axios.post(BASE_URL+`/api/hospitals/${hospitalId}/departments`,JSON.stringify(payload) , {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(createDepartmentFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('New Department created successfully', 'success'))
                dispatch(createDepartmentSuccess(response))
                onComplete()
            }
        }).catch(error => {
            dispatch(notify(error, 'error'));
            dispatch(createDepartmentFailure(error));
        });
    }
};

export const updateDepartment = (departmentId: string, payload: DepartmentUpdatePayloadType, callback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;

        dispatch(updateDepartmentStart());
        axios.patch(BASE_URL+`/api/hospitals/${hospitalId}/departments/${departmentId}`, JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(updateDepartmentFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Department updated successfully', 'success'))
                dispatch(updateDepartmentSuccess(response))
                callback()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(updateDepartmentFailure(error));
        });
    }
};


export const fetchAdminDepartments = () => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;
        dispatch(fetchDepartmentsAccountStart());
        axios.get(BASE_URL+`/api/hospitals/${hospitalId}/departments`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDepartmentsAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchDepartmentsAccountSuccess(response));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchDepartmentsAccountFailure(error));
        });
    }
};
export const fetchDepartments = () => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;
        dispatch(fetchDepartmentsAccountStart());
        axios.get(BASE_URL+`/api/hospitals/${hospitalId}/departments/current-user`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDepartmentsAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchDepartmentsAccountSuccess(response));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchDepartmentsAccountFailure(error));
        });
    }
};

export const fetchDepartment = (id: string, callback:(response: DepartmentType) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;
        dispatch(fetchDepartmentStart());
        axios.get(BASE_URL+`/api/hospitals/${hospitalId}/departments/${id}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDepartmentFailure(response.errorMessage ?? response.error));
            } else {
                callback(response);
                dispatch(fetchDepartmentSuccess(response));
            }
        }).catch(error => {
            dispatch(notify(error, 'error'));
            dispatch(fetchDepartmentFailure(error));
        });
    }
};

    export const deleteDepartment = (departmentId: string) => {
        return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
            const token = getState().authReducer.token;
            const hospitalId = getState().userReducer.user.hospitalId;
        dispatch(deleteDepartmentAccountStart());
        axios.delete(BASE_URL+`/api/hospitals/${hospitalId}/departments/${departmentId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(deleteDepartmentAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(deleteDepartmentAccountSuccess(response));
                dispatch(notify("Account deleted successfully", 'success'));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(deleteDepartmentAccountFailure(error));
        });
    }
};
