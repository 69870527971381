import { notify } from "reapop";
import { RootState } from "../store";
import React from "react";
import axios from "axios";

export const FETCH_CURRENT_USER_DETAILS_START =
  "FETCH_CURRENT_USER_DETAILS_START";
export const FETCH_CURRENT_USER_DETAILS_SUCCESS =
  "FETCH_CURRENT_USER_DETAILS_SUCCESS";
export const FETCH_CURRENT_USER_DETAILS_FAILURE =
  "FETCH_CURRENT_USER_DETAILS_FAILURE";
export const AUTH_LOGIN_START = "AUTH_LOGIN_START";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";
export const AUTH_TOKEN_RENEWAL_START = "AUTH_TOKEN_RENEWAL_START";
export const AUTH_TOKEN_RENEWAL_SUCCESS = "AUTH_TOKEN_RENEWAL_SUCCESS";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";

const BASE_URL = "https://providers-dev-api.dokitari.com";
const fetchCurrentUserDetailsStart = () => ({
  type: FETCH_CURRENT_USER_DETAILS_START,
});

const fetchCurrentUserDetailsSuccess = (
  id: string,
  accountType: string,
  hospitalId: string,
  roles: string[]
) => ({
  type: FETCH_CURRENT_USER_DETAILS_SUCCESS,
  id,
  accountType,
  hospitalId,
  roles,
});

const fetchCurrentUserDetailsFailure = (error: string) => ({
  type: FETCH_CURRENT_USER_DETAILS_FAILURE,
  error,
});

const authLoginStart = () => ({
  type: AUTH_LOGIN_START,
});

const authLoginSuccess = (
  id: string,
  accountType: string,
  hospitalId: string,
  roles: string[],
  refreshToken: string,
  token: string,
  idToken:string
) => ({
  type: AUTH_LOGIN_SUCCESS,
  id,
  accountType,
  hospitalId,
  roles,
  refreshToken,
  token,
  idToken
});

const authLoginFailure = (error: string) => ({
  type: AUTH_LOGIN_FAILURE,
  error,
});

const authLogoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS,
});

const authTokenRenewalSuccess = (refreshToken: string, token: string,idToken:string) => ({
  type: AUTH_TOKEN_RENEWAL_SUCCESS,
  refreshToken,
  token,
  idToken
});

const authTokenRenewalStart = () => ({
  type: AUTH_TOKEN_RENEWAL_START,
});

export const authLogin = (email: string, password: string) => {
  return function (dispatch: React.Dispatch<any>) {
    dispatch(authLoginStart());

    axios
      .post(
        `${BASE_URL}/api/auth/login`,
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.errorMessage) {
          dispatch(notify(response.data.errorMessage, "error"));
          dispatch(authLoginFailure(response.data.errorMessage));
        } else {
          const responseData = response.data;
          dispatch(
            authLoginSuccess(
              responseData.id,
              responseData.accountType,
              responseData.hospitalId,
              responseData.roles,
              responseData.refreshToken,
              responseData.accessToken,
              responseData.idToken
            )
          );
        }
      })
      .catch((error) => {
        dispatch(notify(error, "error"));
        dispatch(authLoginFailure(error));
      });
  };
};
export const fetchCurrentUserDetails = (onFailureCallback?: () => void) => {
  return function async(
    dispatch: React.Dispatch<any>,
    getState: () => RootState
  ) {
    const token = getState().authReducer.token;
    dispatch(fetchCurrentUserDetailsStart());

    axios
      .get(BASE_URL + "/api/users/current-user", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (responseData.errorMessage) {
          dispatch(notify(responseData.errorMessage, "error"));
          if (onFailureCallback) {
            onFailureCallback();
          }
          dispatch(fetchCurrentUserDetailsFailure(responseData.errorMessage));
        } else if (responseData.id) {
          console.log(responseData.id);
          dispatch(
            fetchCurrentUserDetailsSuccess(
              responseData.id,
              responseData.accountType,
              responseData.hospitalId,
              responseData.roles
            )
          );
        } else {
        }
      })
      .catch((error) => {
        if (onFailureCallback) {
          onFailureCallback();
        }
        dispatch(notify(error, "error"));
        dispatch(fetchCurrentUserDetailsFailure(error));
      });
  };
};

export const requestNewPassword = (email: string, callback: () => void) => {
  return function async(dispatch: React.Dispatch<any>) {
    dispatch(authLoginStart());
    axios
      .post(
        BASE_URL + "/api/auth/request-new-password",
        JSON.stringify({ email: email }),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(
            notify(
              "Error: Reset password link has not been sent to email",
              "error"
            )
          );
          dispatch(authLoginFailure("Error"));
        } else {
          dispatch(
            notify("Reset password link has been sent to email", "success")
          );
          dispatch(authLoginFailure(""));
        }
        callback();
      })
      .catch((error) => {
        dispatch(notify(error.message, "error"));
        dispatch(authLoginFailure(error));
        callback();
      });
  };
};

export const authLogout = (callback?: () => void) => {
  return function async(dispatch: React.Dispatch<any>) {
    // let tester = getState().authReducer.tester;
    dispatch(authLoginStart());
    // fetch(`/auth/logout?tester=${tester}`, {
    fetch(`/auth/logout`, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(notify("Logged out successfully", "success"));
        dispatch(authLogoutSuccess());
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(notify(error, "error"));
      });
  };
};

export const renewAuthToken = () => {
  return function async(
    dispatch: React.Dispatch<any>,
    getState: () => RootState
  ) {
    dispatch(authTokenRenewalStart());
    let refreshToken = getState().authReducer.refreshToken;
    axios
      .post(BASE_URL + `/api/auth/refresh-token?token=${refreshToken}`, null, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        if (response.errorMessage) {
          dispatch(notify(response.errorMessage, "error"));
        } else if (!response.idToken || !response.refreshToken) {
          dispatch(
            notify(
              "Unable to renew auth token. Please try again later",
              "error"
            )
          );
        } else {
          dispatch(
            authTokenRenewalSuccess(response.refreshToken, response.accessToken, response.idToken)
          );
        }
      })
      .catch((error) => {
        dispatch(notify(error, "error"));
      });
  };
};
