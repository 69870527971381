import React, {ChangeEventHandler} from 'react';
import './styles.css';
import {Alert} from "antd";

type Props = {
    label: string;
    placeholder: string;
    imgPrefix?: {
        src: string;
        alt: string;
        backgroundColor: string;
    },
    value?: string | number,
    onChange?: ChangeEventHandler<any>,
    className?: string,
    type?: string,
    invalid?: boolean,
    size?: 'default' | 'small',
    disabled?: boolean,
    onClick?:any
};

const TextInput = ({
                       label,
                       placeholder,
                       imgPrefix,
                       value,
                       onChange,
                       className = "",
                       type = 'text',
                       invalid,
                       size = 'default',
                       disabled=false,
                       onClick
                   }: Props) => {

    return (
        <div id={"label-text-input-container"} className={`${className} ${size}`} onClick={onClick}>
            <span>{label}</span>
            <div className={`text-input-container ${(invalid && 'is-invalid')} ${size}`} id={"text-input"}>
                {imgPrefix &&
                <img style={{
                    backgroundColor: imgPrefix.backgroundColor,
                    borderRadius: 8,
                    padding: 8
                }} width={40} height={40} src={imgPrefix.src}
                     alt={imgPrefix.alt}/>}
                <input
                    disabled={disabled}
                    placeholder={placeholder}
                    type={type}
                    className="ant-input ds-input"
                    onChange={onChange}
                    value={value ?? ''}
                />
            </div>
            {invalid && <Alert message={"Invalid field"} type="error" showIcon/>}
        </div>
    );
};

export default TextInput;
