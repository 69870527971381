import {notify} from 'reapop';
import {RootState} from "../store";
import {convertListToIdMap} from "../../utils/utils";
import {
    DoctorAccountCreationPayloadType, DoctorAccountEditPayloadType, DoctorType
} from "../../types/doctor";
import axios from "axios";
import React from "react";

export const CREATE_DOCTOR_ACCOUNT_START = "CREATE_DOCTOR_ACCOUNT_START";
export const CREATE_DOCTOR_ACCOUNT_SUCCESS = "CREATE_DOCTOR_ACCOUNT_SUCCESS";
export const CREATE_DOCTOR_ACCOUNT_FAILURE = "CREATE_DOCTOR_ACCOUNT_FAILURE";

export const EDIT_DOCTOR_ACCOUNT_START = "EDIT_DOCTOR_ACCOUNT_START";
export const EDIT_DOCTOR_ACCOUNT_SUCCESS = "EDIT_DOCTOR_ACCOUNT_SUCCESS";
export const EDIT_DOCTOR_ACCOUNT_FAILURE = "EDIT_DOCTOR_ACCOUNT_FAILURE";

export const FETCH_DOCTORS_ACCOUNTS_START = "FETCH_DOCTORS_ACCOUNTS_START";
export const FETCH_DOCTORS_ACCOUNTS_SUCCESS = "FETCH_DOCTORS_ACCOUNTS_SUCCESS";
export const FETCH_DOCTORS_ACCOUNTS_FAILURE = "FETCH_DOCTORS_ACCOUNTS_FAILURE";

export const FETCH_DOCTOR_ACCOUNT_START = "FETCH_DOCTOR_ACCOUNT_START";
export const FETCH_DOCTOR_ACCOUNT_SUCCESS = "FETCH_DOCTOR_ACCOUNT_SUCCESS";
export const FETCH_DOCTOR_ACCOUNT_FAILURE = "FETCH_DOCTOR_ACCOUNT_FAILURE";

export const DELETE_DOCTOR_ACCOUNT_START = "DELETE_DOCTOR_ACCOUNT_START";
export const DELETE_DOCTOR_ACCOUNT_SUCCESS = "DELETE_DOCTOR_ACCOUNT_SUCCESS";
export const DELETE_DOCTOR_ACCOUNT_FAILURE = "DELETE_DOCTOR_ACCOUNT_FAILURE";

export const createDoctorAccountStart = () => ({
    type: CREATE_DOCTOR_ACCOUNT_START
})

export const createDoctorAccountSuccess = (id: String, payload: DoctorType) => ({
    type: CREATE_DOCTOR_ACCOUNT_SUCCESS,
    id,
    payload
});

const createDoctorAccountFailure = (error: string) => ({
    type: CREATE_DOCTOR_ACCOUNT_FAILURE,
    error
});

export const editDoctorAccountStart = () => ({
    type: EDIT_DOCTOR_ACCOUNT_START
})

export const editDoctorAccountSuccess = (id: String, payload: DoctorType) => ({
    type: EDIT_DOCTOR_ACCOUNT_SUCCESS,
    id,
    payload
});

const editDoctorAccountFailure = (error: string) => ({
    type: EDIT_DOCTOR_ACCOUNT_FAILURE,
    error
});

export const fetchDoctorsAccountStart = () => ({
    type: FETCH_DOCTORS_ACCOUNTS_START
});

export const fetchDoctorsAccountSuccess = (payload: DoctorType[]) => ({
    type: FETCH_DOCTORS_ACCOUNTS_SUCCESS,
    payload: convertListToIdMap(payload)
});

const fetchDoctorsAccountFailure = (error: string) => ({
    type: FETCH_DOCTORS_ACCOUNTS_FAILURE,
    error
});

export const fetchDoctorAccountStart = () => ({
    type: FETCH_DOCTOR_ACCOUNT_START
})

export const fetchDoctorAccountSuccess = (id: string, payload: DoctorType) => ({
    type: FETCH_DOCTOR_ACCOUNT_SUCCESS,
    id,
    payload
});

const fetchDoctorAccountFailure = (error: string) => ({
    type: FETCH_DOCTOR_ACCOUNT_FAILURE,
    error
});

export const deleteDoctorAccountStart = () => ({
    type: DELETE_DOCTOR_ACCOUNT_START
})

export const deleteDoctorAccountSuccess = (payload: DoctorType) => ({
    type: DELETE_DOCTOR_ACCOUNT_SUCCESS,
    payload
});

const deleteDoctorAccountFailure = (error: string) => ({
    type: DELETE_DOCTOR_ACCOUNT_FAILURE,
    error
});

const BASE_URL = "https://providers-dev-api.dokitari.com";
export const createDoctorAccount = (payload: DoctorAccountCreationPayloadType, onComplete: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;

        dispatch(createDoctorAccountStart());
        axios.post(BASE_URL+`/api/doctors?hospital-id=${hospitalId}`, JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => createDoctorAccount(payload, onComplete), (error) => createDoctorAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(createDoctorAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Doctor Account created successfully', 'success'))
                dispatch(createDoctorAccountSuccess(response.userId, response))
                onComplete()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(createDoctorAccountFailure(error));
        });
    }
};


export const editDoctorAccount = (doctorId: string, payload: DoctorAccountEditPayloadType, onComplete?: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;

        dispatch(editDoctorAccountStart());
        axios.patch(BASE_URL+`/api/doctors/${doctorId}?hospital-id=${hospitalId}`, JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => editDoctorAccount(doctorId, payload, onComplete), (error) => createDoctorAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(editDoctorAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Doctor Account updated successfully', 'success'))
                dispatch(editDoctorAccountSuccess(doctorId, response))
                // if (userIsDoctor(getState().authReducer.roles)) {
                //     dispatch(fetchProfileSuccess(response));
                // }
                if(onComplete){
                    onComplete();
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(createDoctorAccountFailure(error));
        });
    }
};


export const fetchDoctors = () => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;
        //let tester = getState().authReducer.tester;
        dispatch(fetchDoctorsAccountStart());
        axios.get(BASE_URL+`/api/doctors?hospital-id=${hospitalId}`,{
            headers: {
                "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchDoctors(), (error) => fetchDoctorsAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDoctorsAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchDoctorsAccountSuccess(response));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchDoctorsAccountFailure(error));
        });
    }
};

export const fetchDoctorAccount = (doctorId: string, callback?: (doctor: DoctorType) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;
        //let tester = getState().authReducer.tester;
        dispatch(fetchDoctorAccountStart());
        axios.get(BASE_URL+`/api/doctors/${doctorId}?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchDoctorAccount(doctorId, callback), (error) => fetchDoctorAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDoctorAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchDoctorAccountSuccess(doctorId, response));
                if (callback) {
                    callback(response)
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchDoctorAccountFailure(error));
        });
    }
};


export const deleteDoctor = (doctorId: string) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().userReducer.user.hospitalId;

        dispatch(deleteDoctorAccountStart());
        axios.delete(BASE_URL+`/api/doctors/${doctorId}?hospital-id=${hospitalId}`,{
            headers: {
                "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => deleteDoctor(doctorId), (error) => deleteDoctorAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(deleteDoctorAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(deleteDoctorAccountSuccess(response));
                dispatch(notify("Account deleted successfully", 'success'));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(deleteDoctorAccountFailure(error));
        });
    }
};
