import {
    CREATE_PATIENT_ACCOUNT_START,
    CREATE_PATIENT_ACCOUNT_SUCCESS,
    CREATE_PATIENT_ACCOUNT_FAILURE,
    FETCH_PATIENTS_ACCOUNT_START,
    FETCH_PATIENTS_ACCOUNT_SUCCESS,
    FETCH_PATIENTS_ACCOUNT_FAILURE,
    FETCH_PATIENT_ACCOUNT_START,
    FETCH_PATIENT_ACCOUNT_SUCCESS,
    FETCH_PATIENT_ACCOUNT_FAILURE,
    DELETE_PATIENT_ACCOUNT_START,
    DELETE_PATIENT_ACCOUNT_SUCCESS,
    DELETE_PATIENT_ACCOUNT_FAILURE,
    EDIT_PATIENT_ACCOUNT_SUCCESS,
    EDIT_PATIENT_ACCOUNT_START,
    EDIT_PATIENT_ACCOUNT_FAILURE
} from "../actions/patientActions";
import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {PatientType} from "../../types/patient";


const initState: { loading: boolean, error?: string, patients: { [id: string]: PatientType } } = {
    patients: {},
    loading: false
};

const patientReducer = (state = initState, action: { type: string, error: string, payload: PatientType | { [id: string]: PatientType } }) => {

    switch (action.type) {
        case CREATE_PATIENT_ACCOUNT_SUCCESS: {
            const patient = action.payload as PatientType;
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [patient.id]: patient
                },
                loading: false,
                error: undefined
            }
        }
        case CREATE_PATIENT_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case CREATE_PATIENT_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case EDIT_PATIENT_ACCOUNT_SUCCESS: {
            const patient = action.payload as PatientType;
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [patient.id]: patient
                },
                loading: false,
                error: undefined
            }
        }
        case EDIT_PATIENT_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case EDIT_PATIENT_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case FETCH_PATIENTS_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_PATIENTS_ACCOUNT_SUCCESS: {

            return {
                ...state,
                patients: action.payload as { [id: string]: PatientType },
                loading: false,
                error: undefined
            }
        }
        case FETCH_PATIENTS_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case FETCH_PATIENT_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_PATIENT_ACCOUNT_SUCCESS: {
            const patient = action.payload as PatientType;
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [patient.id]: patient
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_PATIENT_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case DELETE_PATIENT_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case DELETE_PATIENT_ACCOUNT_SUCCESS: {
            const patient = action.payload as PatientType;
            const patients = state.patients;
            delete patients[patient.id];
            return {
                ...state,
                patients,
                loading: false,
                error: undefined
            }
        }
        case DELETE_PATIENT_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default patientReducer;
