import {RootState} from "../store";
import {notify} from "reapop";
import {
     PartnershipType
} from "../../types/partnership";
import axios from "axios";
import React from "react";

export const FETCH_PARTNERSHIP_START = "FETCH_PARTNERSHIP_START";
export const FETCH_PARTNERSHIP_SUCCESS = "FETCH_PARTNERSHIP_SUCCESS";
export const FETCH_PARTNERSHIP_FAILURE = "FETCH_PARTNERSHIP_FAILURE";

export const fetchPartnershipStart = () => ({
    type: FETCH_PARTNERSHIP_START
})

export const fetchPartnershipSuccess = ( partnership: PartnershipType) => ({
    type: FETCH_PARTNERSHIP_SUCCESS,
    partnership
})

const fetchPartnershipFailure = (error: string) => ({
    type: FETCH_PARTNERSHIP_FAILURE,
    error
});

const BASE_URL = "https://providers-dev-api.dokitari.com";
export const fetchPartnershipDetails = () => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let id = getState().authReducer.id;
        // let tester = getState().authReducer.tester;

        dispatch(fetchPartnershipStart());
        axios.get(BASE_URL+`/api/partnerships/${id}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchPartnershipDetails(), (error) => fetchPartnershipFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchPartnershipFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Partnership fetched successfully', 'success'))
                dispatch(fetchPartnershipSuccess(response))
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchPartnershipFailure(error));
        });
    }
};


