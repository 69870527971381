import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {HospitalType} from "../../types/hospital";
import {
    FETCH_CURRENT_HOSPITAL_FAILURE,
    FETCH_CURRENT_HOSPITAL_START,
    FETCH_CURRENT_HOSPITAL_SUCCESS, FETCH_HOSPITALS_FAILURE,
    FETCH_HOSPITALS_START, FETCH_HOSPITALS_SUCCESS,
    UPDATE_HOSPITAL_FAILURE,
    UPDATE_HOSPITAL_START,
    UPDATE_HOSPITAL_SUCCESS
} from "../actions/hospitalActions";
import {NameId} from "../../types/commons";


const initState: { loading: boolean, error?: string, currentHospital?: HospitalType, hospitals: {[id: string]:NameId} } = {
    loading: false,
    currentHospital: undefined,
    hospitals: {}
};

const hospitalReducer = (state = initState, action: any) => {

    switch (action.type) {

        case FETCH_CURRENT_HOSPITAL_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_CURRENT_HOSPITAL_SUCCESS: {
            return {
                ...state,
                currentHospital: action.payload,
                loading: false,
                error: undefined
            }
        }
        case FETCH_CURRENT_HOSPITAL_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_HOSPITALS_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_HOSPITALS_SUCCESS: {
            return {
                ...state,
                hospitals: action.payload,
                loading: false,
                error: undefined
            }
        }
        case FETCH_HOSPITALS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case UPDATE_HOSPITAL_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case UPDATE_HOSPITAL_SUCCESS: {
            return {
                ...state,
                currentHospital: action.payload,
                loading: false,
                error: undefined
            }
        }
        case UPDATE_HOSPITAL_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default hospitalReducer;
