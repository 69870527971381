import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store, persistor} from './redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import {IntlProvider} from "react-intl";
import enTranslations from './lang/en-US.json';
import {BrowserRouter} from "react-router-dom";
let i18nConfig = {
    locale: 'en',
    messages: enTranslations
};


const app = (
    <Provider store={store}>
        <BrowserRouter>
         <PersistGate loading={null} persistor={persistor}>
            <IntlProvider
                locale={i18nConfig.locale}
                defaultLocale={i18nConfig.locale}
                messages={i18nConfig.messages}
            >
                <App/>
            </IntlProvider>
         </PersistGate>
        </BrowserRouter>
    </Provider>
)


ReactDOM.render(
    <React.StrictMode>
        {app}
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
