import {notify} from 'reapop';
import {RootState} from "../store";
import {convertListToIdMap} from "../../utils/utils";
import {
    FrontDeskAdminAccountCreationPayloadType, FrontDeskAdminAccountUpdatePayloadType, FrontDeskAdminType
} from "../../types/frontDeskAdmin";
import axios from "axios";
import React from "react";

export const CREATE_FRONT_DESK_ADMIN_ACCOUNT_START = "CREATE_FRONT_DESK_ADMIN_ACCOUNT_START";
export const CREATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS = "CREATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS";
export const CREATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE = "CREATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE";

export const UPDATE_FRONT_DESK_ADMIN_ACCOUNT_START = "UPDATE_FRONT_DESK_ADMIN_ACCOUNT_START";
export const UPDATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS = "UPDATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS";
export const UPDATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE = "UPDATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE";


export const DELETE_FRONT_DESK_ADMIN_ACCOUNT_START = "DELETE_FRONT_DESK_ADMIN_ACCOUNT_START";
export const DELETE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS = "DELETE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS";
export const DELETE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE = "DELETE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE";

export const FETCH_FRONT_DESK_ADMINS_ACCOUNT_START = "FETCH_FRONT_DESK_ADMINS_ACCOUNT_START";
export const FETCH_FRONT_DESK_ADMINS_ACCOUNT_SUCCESS = "FETCH_FRONT_DESK_ADMINS_ACCOUNT_SUCCESS";
export const FETCH_FRONT_DESK_ADMINS_ACCOUNT_FAILURE = "FETCH_FRONT_DESK_ADMINS_ACCOUNT_FAILURE";

export const FETCH_FRONT_DESK_ADMIN_ACCOUNT_START = "FETCH_FRONT_DESK_ADMIN_ACCOUNT_START";
export const FETCH_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS = "FETCH_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS";
export const FETCH_FRONT_DESK_ADMIN_ACCOUNT_FAILURE = "FETCH_FRONT_DESK_ADMIN_ACCOUNT_FAILURE";


export const createFrontDeskAdminAccountStart = () => ({
    type: CREATE_FRONT_DESK_ADMIN_ACCOUNT_START
})

export const createFrontDeskAdminAccountSuccess = (payload: FrontDeskAdminType) => ({
    type: CREATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS,
    payload
})

const createFrontDeskAdminAccountFailure = (error: string) => ({
    type: CREATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE,
    error
});


export const updateFrontDeskAdminAccountStart = () => ({
    type: UPDATE_FRONT_DESK_ADMIN_ACCOUNT_START
})

export const updateFrontDeskAdminAccountSuccess = (payload: FrontDeskAdminType) => ({
    type: UPDATE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS,
    payload
})

const updateFrontDeskAdminAccountFailure = (error: string) => ({
    type: UPDATE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE,
    error
});


export const deleteFrontDeskAdminAccountStart = () => ({
    type: DELETE_FRONT_DESK_ADMIN_ACCOUNT_START
})

export const deleteFrontDeskAdminAccountSuccess = (payload: FrontDeskAdminType) => ({
    type: DELETE_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS,
    payload
})

const deleteFrontDeskAdminAccountFailure = (error: string) => ({
    type: DELETE_FRONT_DESK_ADMIN_ACCOUNT_FAILURE,
    error
});

export const fetchFrontDeskAdminsAccountStart = () => ({
    type: FETCH_FRONT_DESK_ADMINS_ACCOUNT_START
})

export const fetchFrontDeskAdminsAccountSuccess = (payload: FrontDeskAdminType[]) => ({
    type: FETCH_FRONT_DESK_ADMINS_ACCOUNT_SUCCESS,
    payload: convertListToIdMap(payload)
})

const fetchFrontDeskAdminsAccountFailure = (error: string) => ({
    type: FETCH_FRONT_DESK_ADMINS_ACCOUNT_FAILURE,
    error
});

export const fetchFrontDeskAdminAccountStart = () => ({
    type: FETCH_FRONT_DESK_ADMIN_ACCOUNT_START
})

export const fetchFrontDeskAdminAccountSuccess = (payload: FrontDeskAdminType) => ({
    type: FETCH_FRONT_DESK_ADMIN_ACCOUNT_SUCCESS,
    payload
})

const fetchFrontDeskAdminAccountFailure = (error: string) => ({
    type: FETCH_FRONT_DESK_ADMIN_ACCOUNT_FAILURE,
    error
});

const BASE_URL = "https://providers-dev-api.dokitari.com";
export const createFrontDeskAdminAccount = (payload: FrontDeskAdminAccountCreationPayloadType,onComplete: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(createFrontDeskAdminAccountStart());
        axios.post(BASE_URL+`/api/front-desk-admins?hospital-id=${hospitalId}`,JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => createFrontDeskAdminAccount(payload), (error) => createFrontDeskAdminAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(createFrontDeskAdminAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Front desk admin Account created successfully', 'success'))
                dispatch(createFrontDeskAdminAccountSuccess(response))
                onComplete()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(createFrontDeskAdminAccountFailure(error));
        });
    }
};

export const updateFrontDeskAdminAccount = (frontDeskAdminId: string, payload: FrontDeskAdminAccountUpdatePayloadType, callback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(updateFrontDeskAdminAccountStart());
        axios.patch(BASE_URL+`/api/front-desk-admins/${frontDeskAdminId}?hospital-id=${hospitalId}`, JSON.stringify(payload),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => updateFrontDeskAdminAccount(frontDeskAdminId, payload, callback), (error) => updateFrontDeskAdminAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(updateFrontDeskAdminAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Front desk admin Account updated successfully', 'success'))
                dispatch(updateFrontDeskAdminAccountSuccess(response))
                callback()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(updateFrontDeskAdminAccountFailure(error));
        });
    }
};

export const deleteFrontDeskAdminAccount = (frontDeskAdminId: string, callback?: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(deleteFrontDeskAdminAccountStart());
        axios.delete(BASE_URL+`/api/front-desk-admins/${frontDeskAdminId}?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => deleteFrontDeskAdminAccount(frontDeskAdminId, callback), (error) => deleteFrontDeskAdminAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(deleteFrontDeskAdminAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Front desk admin Account deleted successfully', 'success'))
                dispatch(deleteFrontDeskAdminAccountSuccess(response))
                if(callback){
                    callback();
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(deleteFrontDeskAdminAccountFailure(error));
        });
    }
};


export const fetchFrontDeskAdmins = () => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(fetchFrontDeskAdminsAccountStart());
        axios.get(BASE_URL+`/api/front-desk-admins?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchFrontDeskAdmins(), (error) => fetchFrontDeskAdminsAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchFrontDeskAdminsAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchFrontDeskAdminsAccountSuccess(response));
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchFrontDeskAdminsAccountFailure(error));
        });
    }
};

export const fetchFrontDeskAdmin = (id: string, callback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;


        dispatch(fetchFrontDeskAdminAccountStart());
        axios.get(BASE_URL+`/api/front-desk-admins/${id}?hospital-id=${hospitalId}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => fetchFrontDeskAdmin(id, callback), (error) => fetchFrontDeskAdminAccountFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchFrontDeskAdminAccountFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchFrontDeskAdminAccountSuccess(response));
                if (callback) {
                    callback();
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchFrontDeskAdminAccountFailure(error));
        });
    }
};

