import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {DepartmentType} from "../../types/department";
import {
    CREATE_DEPARTMENT_START,
    CREATE_DEPARTMENT_SUCCESS,
    CREATE_DEPARTMENT_FAILURE,
    UPDATE_DEPARTMENT_START,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAILURE,
    FETCH_DEPARTMENT_START,
    FETCH_DEPARTMENT_SUCCESS,
    FETCH_DEPARTMENT_FAILURE,
    FETCH_DEPARTMENT_ACCOUNT_SUCCESS,
    FETCH_DEPARTMENTS_ACCOUNT_FAILURE,
    FETCH_DEPARTMENT_ACCOUNT_START,
    DELETE_DEPARTMENT_ACCOUNT_SUCCESS, DELETE_DEPARTMENT_ACCOUNT_FAILURE, DELETE_DEPARTMENT_ACCOUNT_START,
} from '../actions/departmentActions'



const initState: {
    loading: boolean, error?: string| null, departments: { [id: string]: DepartmentType }
} = {
    loading: false,
    departments: {}
};


const departmentReducer = (state = initState, action: any) => {
    switch (action.type) {
        case  CREATE_DEPARTMENT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case CREATE_DEPARTMENT_SUCCESS: {
            return {
                ...state,
                departments: {
                    ...state.departments,
                    [action.payload.userId]: action.payload
                },
                loading: false,
                error: null
            }
        }
        case CREATE_DEPARTMENT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case UPDATE_DEPARTMENT_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case UPDATE_DEPARTMENT_SUCCESS: {
            return {
                ...state,
                departments: {
                    ...state.departments,
                    [action.id!]: action.payload as DepartmentType
                },
                loading: false,
                error: undefined
            }
        }
        case UPDATE_DEPARTMENT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_DEPARTMENT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case FETCH_DEPARTMENT_SUCCESS: {

            return {
                ...state,
                departments:{
                    ...state.departments,
                    [action.id!]:action.payload,
                },
                loading: false,
                error: undefined
            }
        }
        case FETCH_DEPARTMENT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_DEPARTMENT_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case  FETCH_DEPARTMENT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                departments: action.payload as {[id: string]:DepartmentType},
                loading: false,
                error: undefined
            }
        }
        case FETCH_DEPARTMENTS_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case DELETE_DEPARTMENT_ACCOUNT_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case DELETE_DEPARTMENT_ACCOUNT_SUCCESS: {
            const department = action.payload as DepartmentType;
            const departments = state.departments;
            delete departments[department.id];
            return {
                ...state,
                departments,
                loading: false,
                error: undefined
            }
        }
        case DELETE_DEPARTMENT_ACCOUNT_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default departmentReducer;
