import {AUTH_LOGOUT_SUCCESS} from "../actions/authActions";
import {
    CREATE_PATIENT_RECORD_START,
    CREATE_PATIENT_RECORD_SUCCESS,
    CREATE_PATIENT_RECORD_FAILURE,
    UPDATE_PATIENT_RECORD_START,
    UPDATE_PATIENT_RECORD_SUCCESS,
    UPDATE_PATIENT_RECORD_FAILURE,
    FETCH_PATIENT_RECORDS_START, FETCH_PATIENT_RECORDS_FAILURE, FETCH_PATIENT_RECORDS_SUCCESS,
} from "../actions/patientRecordActions";
import {PatientRecordType} from "../../types/patient";


const initState: { loading: boolean, error?: string, records: { [id: string]: PatientRecordType } } = {
    loading: false,
    records: {}
};

const patientRecordReducer = (state = initState, action: any) => {

    switch (action.type) {

        case CREATE_PATIENT_RECORD_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case CREATE_PATIENT_RECORD_SUCCESS: {
            return {
                ...state,
                records: {
                    ...state.records,
                    [action.payload.id]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case CREATE_PATIENT_RECORD_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case UPDATE_PATIENT_RECORD_START: {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        }
        case UPDATE_PATIENT_RECORD_SUCCESS: {
            return {
                ...state,
                records: {
                    ...state.records,
                    [action.payload.id]: action.payload
                },
                loading: false,
                error: undefined
            }
        }
        case UPDATE_PATIENT_RECORD_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        case FETCH_PATIENT_RECORDS_START: {
            return {
                ...state,
                records: {},
                loading: true,
                error: undefined
            };
        }
        case FETCH_PATIENT_RECORDS_SUCCESS: {

            return {
                ...state,
                records: action.payload,
                loading: false,
                error: undefined
            }
        }
        case FETCH_PATIENT_RECORDS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }

        case AUTH_LOGOUT_SUCCESS: {
            return initState
        }
        default: {
            return state
        }
    }
};

export default patientRecordReducer;
