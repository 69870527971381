import {RootState} from "../store";
import {convertListToIdMap} from "../../utils/utils";
import {notify} from "reapop";
import {
    AppointmentCreationPayloadType, AppointmentMeetingNoteType, AppointmentReportPayloadType,
    AppointmentReschedulePayloadType,
    AppointmentType
} from "../../types/appointment";
import dayjs from "dayjs";
import axios from "axios";
import React from "react";

export const REGISTER_APPOINTMENT_START = "REGISTER_APPOINTMENT_START";
export const REGISTER_APPOINTMENT_SUCCESS = "REGISTER_APPOINTMENT_SUCCESS";
export const REGISTER_APPOINTMENT_FAILURE = "REGISTER_APPOINTMENT_FAILURE";

export const RESCHEDULE_APPOINTMENT_START = "RESCHEDULE_APPOINTMENT_START";
export const RESCHEDULE_APPOINTMENT_FAILURE = "RESCHEDULE_APPOINTMENT_FAILURE";

export const FETCH_DOCTOR_APPOINTMENTS_START = "FETCH_DOCTOR_APPOINTMENTS_START";
export const FETCH_DOCTOR_APPOINTMENTS_SUCCESS = "FETCH_DOCTOR_APPOINTMENTS_SUCCESS";
export const FETCH_DOCTOR_APPOINTMENTS_FAILURE = "FETCH_DOCTOR_APPOINTMENTS_FAILURE";

export const FETCH_PATIENT_APPOINTMENTS_START = "FETCH_PATIENT_APPOINTMENTS_START";
export const FETCH_PATIENT_APPOINTMENTS_SUCCESS = "FETCH_PATIENT_APPOINTMENTS_SUCCESS";
export const FETCH_PATIENT_APPOINTMENTS_FAILURE = "FETCH_PATIENT_APPOINTMENTS_FAILURE";

export const FETCH_APPOINTMENTS_START = "FETCH_APPOINTMENTS_START";
export const FETCH_APPOINTMENTS_SUCCESS = "FETCH_APPOINTMENTS_SUCCESS";
export const FETCH_APPOINTMENTS_FAILURE = "FETCH_APPOINTMENTS_FAILURE";

export const FETCH_APPOINTMENT_START = "FETCH_APPOINTMENT_START";
export const FETCH_APPOINTMENT_SUCCESS = "FETCH_APPOINTMENT_SUCCESS";
export const FETCH_APPOINTMENT_FAILURE = "FETCH_APPOINTMENT_FAILURE";

export const CANCEL_APPOINTMENT_START = "CANCEL_APPOINTMENT_START";
export const CANCEL_APPOINTMENT_SUCCESS = "CANCEL_APPOINTMENT_SUCCESS";
export const CANCEL_APPOINTMENT_FAILURE = "CANCEL_APPOINTMENT_FAILURE";

export const ADD_MEETING_NOTE_START = "ADD_MEETING_NOTE_START";
export const ADD_MEETING_NOTE_SUCCESS = "ADD_MEETING_NOTE_SUCCESS";
export const ADD_MEETING_NOTE_FAILURE = "ADD_MEETING_NOTE_FAILURE";

export const CLOSE_APPOINTMENT_START = "CLOSE_APPOINTMENT_START";
export const CLOSE_APPOINTMENT_SUCCESS = "CLOSE_APPOINTMENT_SUCCESS";
export const CLOSE_APPOINTMENT_FAILURE = "CLOSE_APPOINTMENT_FAILURE";


export const registerAppointmentStart = () => ({
    type: REGISTER_APPOINTMENT_START
})

export const registerAppointmentSuccess = (payload: AppointmentType) => ({
    type: REGISTER_APPOINTMENT_SUCCESS,
    payload: payload
})

const registerAppointmentFailure = (error: string) => ({
    type: REGISTER_APPOINTMENT_FAILURE,
    error
});

export const rescheduleAppointmentStart = () => ({
    type: RESCHEDULE_APPOINTMENT_START
})

const rescheduleAppointmentFailure = (error: string) => ({
    type: RESCHEDULE_APPOINTMENT_FAILURE,
    error
});

export const fetchDoctorAppointmentsStart = () => ({
    type: FETCH_DOCTOR_APPOINTMENTS_START
})


export const fetchDoctorAppointmentsSuccess = (doctorId: string, appointments: AppointmentType[]) => ({
    type: FETCH_DOCTOR_APPOINTMENTS_SUCCESS,
    payload: convertListToIdMap(appointments)
});

const fetchDoctorAppointmentsFailure = (error: string) => ({
    type: FETCH_DOCTOR_APPOINTMENTS_FAILURE,
    error
});

export const fetchPatientAppointmentsStart = () => ({
    type: FETCH_PATIENT_APPOINTMENTS_START
})

export const fetchPatientAppointmentsSuccess = (patientId: string, appointments: AppointmentType[]) => ({
    type: FETCH_PATIENT_APPOINTMENTS_SUCCESS,
    payload: convertListToIdMap(appointments)
})

const fetchPatientAppointmentsFailure = (error: string) => ({
    type: FETCH_PATIENT_APPOINTMENTS_FAILURE,
    error
});

export const fetchAppointmentsStart = () => ({
    type: FETCH_APPOINTMENTS_START
})

export const fetchAppointmentsSuccess = (appointments: AppointmentType[]) => ({
    type: FETCH_APPOINTMENTS_SUCCESS,
    payload: convertListToIdMap(appointments)
})

const fetchAppointmentsFailure = (error: string) => ({
    type: FETCH_APPOINTMENTS_FAILURE,
    error
});

export const fetchAppointmentStart = () => ({
    type: FETCH_APPOINTMENT_START
})

export const fetchAppointmentSuccess = (appointment: AppointmentType) => ({
    type: FETCH_APPOINTMENT_SUCCESS,
    payload: appointment
})

const fetchAppointmentFailure = (error: string) => ({
    type: FETCH_APPOINTMENT_FAILURE,
    error
});

export const cancelAppointmentStart = () => ({
    type: CANCEL_APPOINTMENT_START
})

export const cancelAppointmentSuccess = (appointment: AppointmentType) => ({
    type: CANCEL_APPOINTMENT_SUCCESS,
    payload: appointment

})

const cancelAppointmentFailure = (error: string) => ({
    type: CANCEL_APPOINTMENT_FAILURE,
    error
});


export const addMeetingNoteStart = () => ({
    type: ADD_MEETING_NOTE_START
});

export const addMeetingNoteSuccess = (appointmentId: string, payload: AppointmentMeetingNoteType[]) => ({
    type: ADD_MEETING_NOTE_SUCCESS,
    appointmentId,
    payload
});

const addMeetingNoteFailure = (error: string) => ({
    type: ADD_MEETING_NOTE_FAILURE,
    error
});


export const closeAppointmentStart = () => ({
    type: CLOSE_APPOINTMENT_START
});

export const closeAppointmentSuccess = () => ({
    type: CLOSE_APPOINTMENT_SUCCESS
});

const closeAppointmentFailure = (error: string) => ({
    type: CLOSE_APPOINTMENT_FAILURE,
    error
});


const BASE_URL = "https://providers-dev-api.dokitari.com";
export const registerAppointment = (payload: AppointmentCreationPayloadType, successCallback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
    const token = getState().authReducer.token;
        dispatch(registerAppointmentStart());
        axios.post(BASE_URL+`/api/appointments`,JSON.stringify(payload), {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => registerAppointment(payload, successCallback), (error) => registerAppointmentFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(registerAppointmentFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Appointment scheduled successfully', 'success'))
                dispatch(registerAppointmentSuccess(response))
                successCallback()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(registerAppointmentFailure(error));
        });
    }
};


export const rescheduleAppointment = (payload: AppointmentReschedulePayloadType, successCallback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        dispatch(rescheduleAppointmentStart());
        axios.patch(BASE_URL+`/api/appointments/${payload.id}/reschedule`,JSON.stringify(payload), {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            //     dispatch(renewAuthToken(() => rescheduleAppointment(payload, successCallback), (error) => rescheduleAppointmentFailure(error)))

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(rescheduleAppointmentFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Appointment updated successfully', 'success'))
                successCallback()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(rescheduleAppointmentFailure(error));
        });
    }
};


export const fetchDoctorAppointments = (doctorId: string, successCallback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(fetchDoctorAppointmentsStart());
        axios.get(BASE_URL+`/api/appointments/doctors/${doctorId}?hospital-id=${hospitalId}`,{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {


            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDoctorAppointmentsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchDoctorAppointmentsSuccess(doctorId, response))
                successCallback()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchDoctorAppointmentsFailure(error));
        });
    }
};
export const fetchDoctorTodayAppointments = (successCallback?: (response: AppointmentType[]) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const hospitalId = getState().authReducer.hospitalId;
        const doctorId = getState().userReducer.user.id;
        const today = dayjs().format("DD.MM.YYYY");

        dispatch(fetchDoctorAppointmentsStart());
        axios.get(BASE_URL+`/api/appointments/doctors/${doctorId}?hospital-id=${hospitalId}&date=${today}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchDoctorAppointmentsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchDoctorAppointmentsSuccess(doctorId, response))
                if (successCallback) {
                    successCallback(response);
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchDoctorAppointmentsFailure(error));
        });
    }
};
export const fetchPatientAppointments = (patientId: string, successCallback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(fetchPatientAppointmentsStart());
        axios.get(BASE_URL+`/api/appointments/patients/${patientId}?hospital-id=${hospitalId}`,{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchPatientAppointmentsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchPatientAppointmentsSuccess(patientId, response))
                successCallback()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchPatientAppointmentsFailure(error));
        });
    }
};

export const fetchAppointments = (successCallback?: (response:AppointmentMeetingNoteType) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;
        dispatch(fetchAppointmentsStart());
        axios.get(BASE_URL+`/api/appointments/hospitals/${hospitalId}`,{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchAppointmentsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchAppointmentsSuccess(response))
                if(successCallback) {
                    successCallback(response)
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchAppointmentsFailure(error));
        });
    }
};


export const fetchTodayAppointments = (successCallback?: (response: AppointmentType[]) => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(fetchAppointmentsStart());
        const today = dayjs().format("DD.MM.YYYY");
        axios.get(BASE_URL+`/api/appointments/hospitals/${hospitalId}?date=${today}`,{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchAppointmentsFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchAppointmentsSuccess(response))
                if (successCallback) {
                    successCallback(response);
                }
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchAppointmentsFailure(error));
        });
    }
};

export const fetchAppointment = (appointmentId: string) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(fetchAppointmentStart());
        axios.get(BASE_URL+`/api/appointments/${appointmentId}?hospital-id=${hospitalId}`,{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(fetchAppointmentFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(fetchAppointmentSuccess(response))
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(fetchAppointmentFailure(error));
        });
    }
};

export const cancelAppointment = (appointmentId: string) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        let hospitalId = getState().authReducer.hospitalId;

        dispatch(cancelAppointmentStart());
        axios.patch(BASE_URL+`/api/appointments/${appointmentId}/cancel?hospital-id=${hospitalId}`,null, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(cancelAppointmentFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(notify('Appointment cancelled successfully', 'success'));
                dispatch(cancelAppointmentSuccess(response))
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(cancelAppointmentFailure(error));
        });
    }
};

export const addMeetingNote = (appointmentId: string, note: string, callback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        const meetingNote = {note:note};
        dispatch(addMeetingNoteStart());
        axios.post(BASE_URL+`/api/appointments/${appointmentId}/meeting-notes`,JSON.stringify(meetingNote),{
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data;
        }).then(response => {
            console.log('response', response)
            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(addMeetingNoteFailure(response.errorMessage ?? response.error));
            } else {
                console.log('addMeetingNoteSuccess')
                dispatch(addMeetingNoteSuccess(appointmentId, response));
                callback()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(addMeetingNoteFailure(error));
        });
    }
};


export const closeAppointment = (appointmentId: string, payload: AppointmentReportPayloadType, successCallback: () => void) => {
    return function async(dispatch: React.Dispatch<any>,getState: () => RootState) {
        const token = getState().authReducer.token;
        dispatch(closeAppointmentStart());
        axios.put(BASE_URL+`/api/appointments/${appointmentId}/close`,JSON.stringify(payload), {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            return response.data;
        }).then(response => {

            if (response.errorMessage || response.error) {
                dispatch(notify(response.errorMessage ?? response.error, 'error'));
                dispatch(closeAppointmentFailure(response.errorMessage ?? response.error));
            } else {
                dispatch(closeAppointmentSuccess());
                dispatch(notify('Appointment closed successfully', 'success'));
                successCallback()
            }
        }).catch(error => {
            console.log(error);
            dispatch(notify(error, 'error'));
            dispatch(closeAppointmentFailure(error));
        });
    }
};
