import * as React from "react";
import { useEffect } from "react";

import "./App.css";
import "./components/common/buttons/button.css";
import { Switch, Route, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import NotificationsSystem, {
  atalhoTheme,
  dismissNotification,
  setUpNotifications,
} from "reapop";
import { RootState } from "./redux/store";
import { useIntl } from "react-intl";
import {
  fetchCurrentUserDetails,
  renewAuthToken,
} from "./redux/actions/authActions";
import { Spin } from "antd";
import Login from "./pages/login/Login";
import { jwtDecode } from "jwt-decode";

const CustomError = React.lazy(() => import("../src/pages/errorPage/404"));
const MainContainer = React.lazy(
  () => import("./pages/main_container/MainContainer")
);
const PartnershipHomePage = React.lazy(
  () => import("./pages/partnership/PartnershipHomePage")
);

type Props = StateProps & DispatchProps;

function App({
  authenticated,
  loading,
  fetchCurrentUserDetails,
  renewAuthToken,
  idToken,
}: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const notifications = useSelector((state: RootState) => state.notifications);
  const history = useHistory();

  if (!authenticated) {
    history.push("/login");
  }

  useEffect(() => {
    fetchCurrentUserDetails();
  }, []);

  useEffect(() => {
    const idTokenValue = idToken;
    if (authenticated && idTokenValue) {
      const tokenExpire = jwtDecode<any>(idTokenValue).exp;
      if (tokenExpire && tokenExpire < Date.now() / 1000) {
        renewAuthToken();
      }
    }
  }, [authenticated]);

  setUpNotifications({
    defaultProps: {
      position: "top-right",
      dismissible: true,
      showDismissButton: true,
      dismissAfter: 5000,
    },
  });

  return (
    <>
      <Spin spinning={loading}>
        <React.Fragment>
          <React.Suspense
            fallback={
              <div className="animated fadeIn pt-3 text-center">
                {intl.formatMessage({ id: "loading_3dots" })}
              </div>
            }
          >
            {authenticated ? (
              <>
                <NotificationsSystem
                  notifications={notifications}
                  dismissNotification={(id) =>
                    dispatch(dismissNotification(id))
                  }
                  theme={atalhoTheme}
                />
                <Switch>
                  <Route
                    path={"/partnership"}
                    component={PartnershipHomePage}
                  />
                  <Route path={"/"} component={MainContainer} />
                </Switch>
              </>
            ) : (
              <>
                <NotificationsSystem
                  notifications={notifications}
                  dismissNotification={(id) =>
                    dispatch(dismissNotification(id))
                  }
                  theme={atalhoTheme}
                />
                <Switch>
                  <Route path={"/unauthorized"} component={CustomError} />
                  <Route path={"/login"} component={Login} />
                </Switch>
              </>
            )}
          </React.Suspense>
        </React.Fragment>
      </Spin>
    </>
  );
}

interface StateProps {
  authenticated: boolean;
  loading: boolean;
  error: string | null;
  idToken: string | null;
}

interface DispatchProps {
  fetchCurrentUserDetails: () => void;
  renewAuthToken: () => void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    authenticated: !!(state.authReducer.roles && state.authReducer.id),
    loading: state.authReducer.loading,
    error: state.authReducer.error ?? null,
    idToken: state.authReducer.idToken ?? null,
  };
};

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => {
  return {
    fetchCurrentUserDetails: () => dispatch(fetchCurrentUserDetails()),
    renewAuthToken: () => dispatch(renewAuthToken()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
